/** @format */
import React, { useState } from "react";
import { Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import ImgCrop from "antd-img-crop";
import styled from "styled-components";
import { Colors } from "../../../theme/maps/Colors";
import placeHolder from "../../../assets/images/profilePicturePlaceholder.png";
import { AddImageInput } from "./partials/Input";

interface Props extends UploadProps {
  defaultImage?: string;
  handleUpload: (e: FileList) => void;
}

export const UIImageUploader = ({ defaultImage, handleUpload }: Props): JSX.Element => {
  const [fileList, setFileList] = useState<object[]>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: defaultImage || placeHolder,
    },
  ]);

  const onChange = ({ fileList: newFileList }: UploadChangeParam): void => {
    setFileList(newFileList);
  };

  const uploadNew = (fileList: FileList): void => {
    handleUpload(fileList);
  };

  //@ts-ignore
  const onPreview = async (file): Promise<void> => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = (): void => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    //@ts-ignore
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div style={{ position: "relative", textAlign: "center", width: "180px" }}>
      <ImgCrop rotate>
        <StyledUpload
          listType="picture-card"
          //@ts-ignore
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
        ></StyledUpload>
      </ImgCrop>
      <div
        style={{
          display: "inline-block",
          position: "absolute",
          right: "40px",
          bottom: "5px",
          zIndex: 9999,
        }}
      >
        <AddImageInput handleFiles={uploadNew} />
        {/*         <img src={addButton} width="42px" />
         */}{" "}
      </div>
    </div>
  );
};

const StyledUpload = styled(Upload)<Props>`
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 50%;
    padding: 0;
    border: 1px solid ${Colors.title};
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0;
  }

  .ant-upload-list-item-info {
    border-radius: 50%;
  }
`;
