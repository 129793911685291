/** @format */

import { TNoticeList, TNoticeTable } from "../declarations/maps/notices";
import { TStore } from "../declarations/store";
import { DEFAULT_TABLE_STATE } from "../../constants/defaultPagination";

export const selectorNoticeListTable = (store: TStore): TNoticeTable =>
  store.notices.list || DEFAULT_TABLE_STATE;

export const selectorNoticeList = (store: TStore): TNoticeList => store.notices.list?.data || [];
