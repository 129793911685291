/** @format */

import { Col, Row } from "antd";
import React, { FC } from "react";
import { userDocumentColumns } from "../../../../constants/tables/userDocumentColumns";
import {
  TUserDocument,
  utilityFormatUserDocumentsToTableDataSourceList,
} from "../../../../utils/format/userDocumentsTable";
import { UIButton } from "../../../ui/Button";
import { UITable } from "../../../ui/Table";

interface Props {
  onAddDocument: () => void;
  documents: TUserDocument[];
}

export const DocumentsTabEdit: FC<Props> = ({ onAddDocument, documents }: Props): JSX.Element => {
  const data = utilityFormatUserDocumentsToTableDataSourceList(documents);

  return (
    <>
      <Row justify="end">
        <Col>
          <UIButton type="primary" onClick={onAddDocument}>
            + Nuovo Documento
          </UIButton>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Col xs={24}>
          <UITable size="middle" columns={userDocumentColumns} dataSource={data} />
        </Col>
      </Row>
    </>
  );
};
