/** @format */

import React from "react";
import UploadIcon from "../../../../assets/svg/UploadIcon.svg";
import { Col, Form, Row } from "antd";
import { UIText } from "../../../ui/Text";
import CustomForm from "./CustomForm";
import UIFormItemDragger from "../../../ui/FormItemDragger";

const AdditionalFields = (): JSX.Element => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item name="customForms">
            <CustomForm />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UIText strong>Allegati</UIText>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UIText>Allega qui i riferimento normativi</UIText>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item name="attachments" valuePropName="fileList">
            <UIFormItemDragger multiple>
              <p className="ant-upload-drag-icon">
                <img width={80} src={UploadIcon} />
              </p>
              <p className="ant-upload-text">Trascina qui il file</p>
              <p className="ant-upload-hint">Oppure scegli il file da caricare</p>
            </UIFormItemDragger>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AdditionalFields;
