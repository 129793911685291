/** @format */

import React, { useEffect } from "react";
import Export from "../../../assets/svg/export.svg";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { useDispatch, useSelector } from "react-redux";
import { actionUsersListRequest } from "../../../redux-modules/actions/actionsUsers";
import {
  selectorUserListPageSize,
  selectorUsersList,
} from "../../../redux-modules/selectors/selectorsUsers";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { TablePaginationConfig } from "antd/lib/table";
import {
  selectorUserDeleted,
  selectorUsersListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { userListColumns } from "../../../constants/tables/userListColumns";
import { utilityFormatUsersToTableDataSourceList } from "../../../utils/format/userTable";
import { UIImage } from "../../ui/Image";
import { UICard } from "../../ui/Card";
import { UIText } from "../../ui/Text";
import UITransition from "../../ui/Transition";
import { UILink } from "../../ui/Link";

export const ViewUserList = (): JSX.Element => {
  const dispatch = useDispatch();
  const userList = useSelector(selectorUsersList);
  const userListLoading = useSelector(selectorUsersListLoading);
  const isUserDeleted = useSelector(selectorUserDeleted);
  const defaultPageSize = useSelector(selectorUserListPageSize);

  useEffect(() => {
    if (isUserDeleted)
      dispatch(
        actionUsersListRequest({
          query: debouncedQuery || undefined,
          columns: ["name", "surname", "email"],
          page_size: defaultPageSize,
        }),
      );
  }, [isUserDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedUserList = utilityFormatUsersToTableDataSourceList(userList.data);
  const formattedWithIcon = formattedUserList.map(el => ({
    ...el,
    avatar: (
      //@ts-ignore
      <UIImage src={el.avatar} round imgWidth="56px" borderColor={Colors.title} imgHeight="56px" />
    ),
  }));
  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionUsersListRequest({
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
        columns: ["name", "surname", "email"],
        page_size: pagination.pageSize,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      actionUsersListRequest({
        query: debouncedQuery || undefined,
        columns: ["name", "surname", "email"],
        page_size: defaultPageSize,
      }),
    );
  }, [debouncedQuery]);
  console.log(userList);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Dipendenti
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.usersNew}
            label={<UIButton type="primary">Crea Dipendente</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={14} />
          <Col span={5}>
            <UISearch placeholder="Cerca..." onChange={setQuery} value={currentQuery} />
          </Col>
          <Col span={5}>
            <UIButton style={{ width: "100%", height: "100%" }}>
              <UIImage css={"margin-right: 8px;"} imgWidth={"13px"} src={Export} />
              <UIText>Esporta</UIText>
            </UIButton>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              loading={userListLoading}
              columns={userListColumns}
              dataSource={formattedWithIcon}
              onChange={handleTableChange}
              pagination={{
                ...userList.pagination,
              }}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
