/** @format */

import { TUI } from "../declarations/maps/ui";
import { TActionType } from "../declarations/actionTypes";
import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionAppLoadingFailure,
  TActionAppLoadingRequest,
  TActionAppLoadingSuccess,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
} from "../declarations/actions/ui";
import {
  TActionUserAvatarReset,
  TActionUserAvatarSuccess,
  TActionUserCreateReset,
  TActionUserCreateSuccess,
  TActionUserDeleteReset,
  TActionUserDeleteSuccess,
  TActionUserDocumentUploadReset,
  TActionUserDocumentUploadSuccess,
  TActionUsersListLoadingFailure,
  TActionUsersListLoadingRequest,
  TActionUsersListLoadingSuccess,
  TActionUserUpdateReset,
  TActionUserUpdateSuccess,
} from "../declarations/actions/users";
import {
  TActionGroupCreateReset,
  TActionGroupCreateSuccess,
  TActionGroupDeleteReset,
  TActionGroupDeleteSuccess,
  TActionGroupsListLoadingFailure,
  TActionGroupsListLoadingRequest,
  TActionGroupsListLoadingSuccess,
  TActionGroupUpdateReset,
  TActionGroupUpdateSuccess,
} from "../declarations/actions/groups";
import {
  TActionLocationCreateReset,
  TActionLocationCreateSuccess,
  TActionLocationDeleteReset,
  TActionLocationDeleteSuccess,
  TActionLocationUpdateReset,
  TActionLocationUpdateSuccess,
} from "../declarations/actions/location";
import {
  TActionNoticeCreateFailure,
  TActionNoticeCreateRequest,
  TActionNoticeCreateReset,
  TActionNoticeCreateSuccess,
  TActionNoticeListFailure,
  TActionNoticeListRequest,
  TActionNoticeListReset,
  TActionNoticeListSuccess,
} from "../declarations/actions/notices";
import {
  TActionDocumentTypeCreateReset,
  TActionDocumentTypeCreateSuccess,
  TActionDocumentTypeDeleteReset,
  TActionDocumentTypeDeleteSuccess,
  TActionDocumentTypeUpdateReset,
  TActionDocumentTypeUpdateSuccess,
} from "../declarations/actions/documentType";
import { showNotification } from "../../utils/showNotification";
import { TActionLoginFailure, TActionLoginReset } from "../declarations/actions/auth";

type Actions =
  | TActionLoginReset
  | TActionLoginFailure
  | TActionAppLoadingRequest
  | TActionAppLoadingSuccess
  | TActionAppLoadingFailure
  | TActionUsersListLoadingRequest
  | TActionUsersListLoadingSuccess
  | TActionUsersListLoadingFailure
  | TActionUserCreateSuccess
  | TActionUserCreateReset
  | TActionGroupsListLoadingRequest
  | TActionGroupsListLoadingSuccess
  | TActionGroupsListLoadingFailure
  | TActionGroupCreateSuccess
  | TActionGroupCreateReset
  | TActionLocationCreateReset
  | TActionLocationCreateSuccess
  | TActionGroupUpdateReset
  | TActionGroupUpdateSuccess
  | TActionGroupDeleteReset
  | TActionGroupDeleteSuccess
  | TActionUserUpdateReset
  | TActionUserUpdateSuccess
  | TActionUserDeleteSuccess
  | TActionUserDeleteReset
  | TActionLocationUpdateReset
  | TActionLocationUpdateSuccess
  | TActionLocationDeleteSuccess
  | TActionLocationDeleteReset
  | TActionUserAvatarSuccess
  | TActionUserAvatarReset
  | TActionNoticeListRequest
  | TActionNoticeListSuccess
  | TActionNoticeListFailure
  | TActionNoticeListReset
  | TActionNoticeCreateRequest
  | TActionNoticeCreateSuccess
  | TActionNoticeCreateFailure
  | TActionNoticeCreateReset
  | TActionNoticeListReset
  | TActionUserDocumentUploadSuccess
  | TActionUserDocumentUploadReset
  | TActionDocumentTypeCreateSuccess
  | TActionDocumentTypeCreateReset
  | TActionDocumentTypeUpdateSuccess
  | TActionDocumentTypeUpdateReset
  | TActionDocumentTypeDeleteSuccess
  | TActionDocumentTypeDeleteReset
  | TActionAlertGenerate
  | TActionAlertRemove
  | TActionStaticAlertGenerate
  | TActionStaticAlertRemove;

export const reducerUI = (prevStore: TUI = {}, action: Actions): TUI => {
  switch (action.type) {
    case TActionType.loginFailure:
      return { ...prevStore, loginValidation: action.payload };
    case TActionType.loginReset:
      return { ...prevStore, loginValidation: {} };
    case TActionType.appLoadingRequest:
      return { ...prevStore, loading: true };
    case TActionType.appLoadingSuccess:
      return { ...prevStore, loading: false };
    case TActionType.appLoadingFailure:
      return { ...prevStore, loading: false };
    case TActionType.alertGenerate:
      return { ...prevStore, alert: action.payload };
    case TActionType.alertRemove:
      return { ...prevStore, alert: undefined };
    case TActionType.staticAlertGenerate:
      return { ...prevStore, staticAlert: action.payload };
    case TActionType.staticAlertRemove:
      return { ...prevStore, staticAlert: undefined };
    case TActionType.usersListLoadingRequest:
      return { ...prevStore, usersListLoading: true };
    case TActionType.usersListLoadingSuccess:
      return { ...prevStore, usersListLoading: false };
    case TActionType.usersListLoadingFailure:
      return { ...prevStore, usersListLoading: false };
    case TActionType.groupsListLoadingRequest:
      return { ...prevStore, groupsListLoading: true };
    case TActionType.groupsListLoadingSuccess:
      return { ...prevStore, groupsListLoading: false };
    case TActionType.groupsListLoadingFailure:
      return { ...prevStore, groupsListLoading: false };
    case TActionType.userCreateSuccess:
      return { ...prevStore, userCreated: true };
    case TActionType.userCreateReset:
      return { ...prevStore, userCreated: false };
    case TActionType.userUpdateSuccess:
      return { ...prevStore, userUpdated: true };
    case TActionType.userUpdateReset:
      return { ...prevStore, userUpdated: false };
    case TActionType.userDeleteSuccess:
      return { ...prevStore, userDeleted: true };
    case TActionType.userDeleteReset:
      return { ...prevStore, userDeleted: false };
    case TActionType.userAvatarSuccess:
      return { ...prevStore, userAvatarUpdated: true };
    case TActionType.userAvatarReset:
      return { ...prevStore, userAvatarUpdated: false };
    case TActionType.userDocumentUploadSuccess:
      return { ...prevStore, userDocumentUpdated: true };
    case TActionType.userDocumentUploadReset:
      return { ...prevStore, userDocumentUpdated: false };
    case TActionType.groupCreateSuccess:
      return { ...prevStore, groupCreated: true };
    case TActionType.groupCreateReset:
      return { ...prevStore, groupCreated: false };
    case TActionType.groupUpdateSuccess:
      return { ...prevStore, groupUpdated: true };
    case TActionType.groupUpdateReset:
      return { ...prevStore, groupUpdated: false };
    case TActionType.groupDeleteSuccess:
      return { ...prevStore, groupDeleted: true };
    case TActionType.groupDeleteReset:
      return { ...prevStore, groupDeleted: false };
    case TActionType.locationCreateSuccess:
      return { ...prevStore, locationCreated: true };
    case TActionType.locationCreateReset:
      return { ...prevStore, locationCreated: false };
    case TActionType.locationUpdateSuccess:
      return { ...prevStore, locationUpdated: true };
    case TActionType.locationUpdateReset:
      return { ...prevStore, locationUpdated: false };
    case TActionType.locationDeleteSuccess:
      return { ...prevStore, locationDeleted: true };
    case TActionType.locationDeleteReset:
      return { ...prevStore, locationDeleted: false };
    case TActionType.documentTypeCreateSuccess:
      return { ...prevStore, documentTypeCreated: true };
    case TActionType.documentTypeCreateReset:
      return { ...prevStore, documentTypeCreated: false };
    case TActionType.documentTypeUpdateSuccess:
      return { ...prevStore, documentTypeUpdated: true };
    case TActionType.documentTypeUpdateReset:
      return { ...prevStore, documentTypeUpdated: false };
    case TActionType.documentTypeDeleteSuccess:
      return { ...prevStore, documentTypeDeleted: true };
    case TActionType.documentTypeDeleteReset:
      return { ...prevStore, documentTypeDeleted: false };
    case TActionType.noticeListRequest:
      return { ...prevStore, noticeListStatus: "pending" };
    case TActionType.noticeListSuccess:
      return { ...prevStore, noticeListStatus: "success" };
    case TActionType.noticeListFailure:
      return { ...prevStore, noticeListStatus: "failure" };
    case TActionType.noticeListReset:
      return { ...prevStore, noticeListStatus: "idle" };
    case TActionType.noticeCreateRequest:
      return { ...prevStore, noticeCreateStatus: "pending" };
    case TActionType.noticeCreateSuccess:
      showNotification("success", "Operazione Completata", "L'avviso è stato creato con successo");
      return { ...prevStore, noticeCreateStatus: "success" };
    case TActionType.noticeCreateFailure:
      showNotification(
        "error",
        "C'è stato un errore",
        "Si è verificato un errore durante la creazione dell'avviso",
      );
      return { ...prevStore, noticeCreateStatus: "failure" };
    case TActionType.noticeCreateReset:
      return { ...prevStore, noticeCreateStatus: "idle" };
    default:
      return prevStore;
  }
};
