/** @format */

import { Col, Row } from "antd";
import React, { useState } from "react";
import { UIFilePreview } from "../../components/ui/FilePreview";

export const userDocumentColumns = [
  {
    title: "Titolo",
    dataIndex: "filename",
    key: "filename",
    showMobile: true,
  },
  {
    title: "Tipologia",
    dataIndex: "type",
    key: "type",
    showMobile: true,
  },
  {
    title: "Valido fino",
    dataIndex: "valid_at",
    key: "share",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);

  const showModal = (): void => setVisiblePreview(true);
  const hideModal = (): void => setVisiblePreview(false);

  //@ts-ignore
  const removeItem = (): void => console.log("elimino");

  return (
    <Row gutter={[12, 0]}>
      <Col onClick={showModal}>
        <a>Anteprima</a>
      </Col>
      |
      <Col onClick={removeItem}>
        <a>Elimina</a>
      </Col>
      <UIFilePreview pdfUrl={row.storage_url} visible={visiblePreview} onDismiss={hideModal} />
    </Row>
  );
};
