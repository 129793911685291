/** @format */
import {
  TActionNoticeCreateRequest,
  TActionNoticeListRequest,
} from "../declarations/actions/notices";
import { call, put } from "redux-saga/effects";
import { apiNoticeList } from "../../api/notices/apiNotice";
import {
  actionNoticeCreateFailure,
  actionNoticeCreateSuccess,
  actionNoticeListFailure,
  actionNoticeListSuccess,
} from "../actions/actionsNotices";
import { utilityFormatApiNoticeList } from "../../utils/format/apiNotice";
import { AxiosResponse } from "axios";
import { TAPINoticeList } from "../declarations/apis/notices";
import { TAPIMeta, TAPIResponse } from "../declarations/apis/general";
import { utilityFormatApiMetaToPagination } from "../../utils/format/apiMetaToPagination";
import { apiNoticeCreate } from "../../api/notices/apiNoticeCreate";

export function* sagaNoticeList(action: TActionNoticeListRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse<TAPINoticeList>> = yield call(
      apiNoticeList,
      action.payload,
    );

    const formattedData = utilityFormatApiNoticeList(response.data.data);
    const formattedMeta = utilityFormatApiMetaToPagination(response.data.meta as TAPIMeta);

    yield put(actionNoticeListSuccess({ data: formattedData, pagination: formattedMeta }));
  } catch (e) {
    //@ts-ignore
    yield put(actionNoticeListFailure(e.stack));
  }
}

export function* sagaNoticeCreate(action: TActionNoticeCreateRequest): unknown {
  try {
    yield call(apiNoticeCreate, action.payload);

    yield put(actionNoticeCreateSuccess());
  } catch (e) {
    //@ts-ignore
    yield put(actionNoticeCreateFailure(e.stack));
  }
}
