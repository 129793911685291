
import { TAPIResponse } from "../../redux-modules/declarations/apis/general";
import { TGroup, TGroupDataSource, TGroupDataSourceList, TGroupList } from "../../redux-modules/declarations/maps/groups";
import { utilityFormatApiGroupList } from "./apiGroup";

export const utilityFormatGroupTable = (
    list: TAPIResponse<TGroup[]>,
    loading: boolean = false,
  ): TGroupList => ({
    data: utilityFormatApiGroupList(list.data), //passaggio al momento inutile, ma che servirà per tutte le altre (anche per questa, refactoring)
    pagination: {
      current: list.meta?.current_page || 0,
      pageSize: list.meta?.per_page || 0,
      lastPage: list.meta?.last_page || 0,
      total: list.meta?.total || 0,
    },
    loading,
  });
  
  export const utilityFormatGroupsToTableDataSource = (
    group: TGroup,
  ): TGroupDataSource => ({
    id: group.id,
    name: group.name,
    //@ts-ignore
    location: group.location || '',
    //@ts-ignore
    usersCount: group.usersCount || ''
  });
  
  export const utilityFormatGroupsToTableDataSourceList = (
    list: TGroup[],
  ): TGroupDataSourceList =>
    list.map(group => utilityFormatGroupsToTableDataSource(group));
  