/** @format */

import {
  TActionUsersListRequest,
  TActionUsersListSuccess,
  TActionUsersListFailure,
  TActionUsersListLoadingFailure,
  TActionUsersListLoadingRequest,
  TActionUsersListLoadingSuccess,
  TActionUserCreateFailure,
  TActionUserCreateRequest,
  TActionUserCreateSuccess,
  TActionUserUpdateFailure,
  TActionUserUpdateRequest,
  TActionUserUpdateSuccess,
  TActionUserDeleteFailure,
  TActionUserDeleteRequest,
  TActionUserDeleteSuccess,
  TActionUserDeleteReset,
  TActionUserUpdateReset,
  TActionUserAvatarFailure,
  TActionUserAvatarRequest,
  TActionUserAvatarReset,
  TActionUserAvatarSuccess,
  TActionUserDocumentUploadFailure,
  TActionUserDocumentUploadRequest,
  TActionUserDocumentUploadReset,
  TActionUserDocumentUploadSuccess,
  TActionUserListPageSizeRequest,
  TActionUserListPageSizeSuccess,
  TActionUserListPageSizeFailure,
} from "../declarations/actions/users";
/** @format */

import { TAction, TActionType } from "../declarations/actionTypes";
import { TAPIUsersListRequest } from "../declarations/apis/users";
import {
  TUserAvatarRequest,
  TUserCreateRequest,
  TUserDocumentUploadRequest,
  TUserList,
  TUserUpdateRequest,
} from "../declarations/maps/users";

//List
export const actionUsersListRequest = (payload: TAPIUsersListRequest): TActionUsersListRequest => ({
  type: TActionType.usersListRequest,
  payload,
});

export const actionUsersListSuccess = (payload: TUserList): TActionUsersListSuccess => ({
  type: TActionType.usersListSuccess,
  payload,
});

export const actionUsersListFailure = (): TActionUsersListFailure => ({
  type: TActionType.usersListFailure,
});

//---

//Loading

export const actionUsersListLoadingRequest = (): TActionUsersListLoadingRequest => ({
  type: TActionType.usersListLoadingRequest,
});

export const actionUsersListLoadingSuccess = (): TActionUsersListLoadingSuccess => ({
  type: TActionType.usersListLoadingSuccess,
});

export const actionUsersListLoadingFailure = (): TActionUsersListLoadingFailure => ({
  type: TActionType.usersListLoadingFailure,
});

// ---
//Create
export const actionUserCreateRequest = (payload: TUserCreateRequest): TActionUserCreateRequest => ({
  type: TActionType.userCreateRequest,
  payload,
});
export const actionUserCreateSuccess = (payload: string): TActionUserCreateSuccess => ({
  type: TActionType.userCreateSuccess,
  payload,
});
export const actionUserCreateFailure = (): TActionUserCreateFailure => ({
  type: TActionType.userCreateFailure,
});
export const actionUserCreateReset = (): TAction => ({ type: TActionType.userCreateReset });
// ---

// Update
export const actionUserUpdateRequest = (payload: TUserUpdateRequest): TActionUserUpdateRequest => ({
  type: TActionType.userUpdateRequest,
  payload,
});

export const actionUserUpdateSuccess = (): TActionUserUpdateSuccess => ({
  type: TActionType.userUpdateSuccess,
});

export const actionUserUpdateFailure = (): TActionUserUpdateFailure => ({
  type: TActionType.userUpdateFailure,
});

export const actionUserUpdateReset = (): TActionUserUpdateReset => ({
  type: TActionType.userUpdateReset,
});
// ---
// Delete
export const actionUserDeleteRequest = (payload: string): TActionUserDeleteRequest => ({
  type: TActionType.userDeleteRequest,
  payload,
});

export const actionUserDeleteSuccess = (payload: string): TActionUserDeleteSuccess => ({
  type: TActionType.userDeleteSuccess,
  payload,
});

export const actionUserDeleteFailure = (): TActionUserDeleteFailure => ({
  type: TActionType.userDeleteFailure,
});
export const actionUserDeleteReset = (): TActionUserDeleteReset => ({
  type: TActionType.userDeleteReset,
});
// ---
// Avatar Upload
export const actionUserAvatarRequest = (payload: TUserAvatarRequest): TActionUserAvatarRequest => ({
  type: TActionType.userAvatarRequest,
  payload,
});

export const actionUserAvatarSuccess = (): TActionUserAvatarSuccess => ({
  type: TActionType.userAvatarSuccess,
});

export const actionUserAvatarFailure = (): TActionUserAvatarFailure => ({
  type: TActionType.userAvatarFailure,
});

export const actionUserAvatarReset = (): TActionUserAvatarReset => ({
  type: TActionType.userAvatarReset,
});
// ---
// User Document Upload
export const actionUserDocumentUploadRequest = (
  payload: TUserDocumentUploadRequest,
): TActionUserDocumentUploadRequest => ({
  type: TActionType.userDocumentUploadRequest,
  payload,
});

export const actionUserDocumentUploadSuccess = (): TActionUserDocumentUploadSuccess => ({
  type: TActionType.userDocumentUploadSuccess,
});

export const actionUserDocumentUploadFailure = (): TActionUserDocumentUploadFailure => ({
  type: TActionType.userDocumentUploadFailure,
});

export const actionUserDocumentUploadReset = (): TActionUserDocumentUploadReset => ({
  type: TActionType.userDocumentUploadReset,
});
// ---

export const actionUserListPageSizeRequest = (payload: number): TActionUserListPageSizeRequest => ({
  type: TActionType.usersListPageSizeRequest,
  payload,
});

export const actionUserListPageSizeSuccess = (): TActionUserListPageSizeSuccess => ({
  type: TActionType.usersListPageSizeSuccess,
});

export const actionUserListPageSizeFailure = (): TActionUserListPageSizeFailure => ({
  type: TActionType.usersListPageSizeFailure,
});
