/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaDocumentCreate, sagaDocumentDelete, sagaDocumentList } from "../sagas/sagasDocuments";

export function* watcherDocuments(): unknown {
  yield takeLatest(TActionType.documentCreateRequest, sagaDocumentCreate);
  yield takeLatest(TActionType.documentListRequest, sagaDocumentList);
  yield takeLatest(TActionType.documentDeleteRequest, sagaDocumentDelete);
}
