/** @format */

import React, { FC, useState, Fragment, useEffect } from "react";
import { Col, Divider, Row, Select } from "antd";
import { UIInput } from "../../../ui/Input";
import { UIButton } from "../../../ui/Button";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { Colors } from "../../../../theme/maps/Colors";

interface Props {
  value?: CustomFormItem[];
  onChange?: (value: CustomFormItem[]) => void;
}

interface CustomFormItem {
  title: string;
  type_id: string;
}

interface CustomFormList {
  [key: number]: CustomFormItem;
}

const CustomForm: FC<Props> = ({ value = [], onChange }): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<CustomFormList>(
    value?.reduce((list, current, index) => ({ ...list, [index]: current }), {}) || {},
  );
  const [tempTitle, setTempTitle] = useState<string | undefined>("");
  const [tempType, setTempType] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (onChange) onChange(Object.values(currentValue));
  }, [currentValue]);

  const handleOnRemove = (id: number): void => {
    const { [id]: _, ...newValue } = currentValue;
    setCurrentValue(newValue);
  };

  const handleOnAddTempTypeClick = (): void => setTempType("");

  const handleOnAddItemClick = (): void => {
    if (!tempTitle || !tempType) return;
    setCurrentValue({
      ...currentValue,
      [Object.values(currentValue).length]: { title: tempTitle, type_id: tempType },
    });
    setTempType(undefined);
    setTempTitle(undefined);
  };

  const handleTitleUpdate = (id: number, newTitle: string): void =>
    setCurrentValue({ ...currentValue, [id]: { ...currentValue[id], title: newTitle } });

  const handleTypeUpdate = (id: number, newType: string): void =>
    setCurrentValue({ ...currentValue, [id]: { ...currentValue[id], type_id: newType } });

  return (
    <>
      {Object.keys(currentValue).map(currentKey => (
        <Fragment key={currentKey}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <UIText strong>Titolo</UIText>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={20}>
                  <UIInput
                    placeholder="Titolo"
                    value={currentValue[JSON.parse(currentKey)].title}
                    onChange={(e): void =>
                      handleTitleUpdate(JSON.parse(currentKey), e.target.value)
                    }
                  />
                </Col>
                <Col span={2}>
                  <UIButton
                    bgColor={Colors.title}
                    color={Colors.primary}
                    hoverBgColor={Colors.darkBlue}
                    onClick={(): void => handleOnRemove(JSON.parse(currentKey))}
                  >
                    -
                  </UIButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <UIText strong>Tipologia</UIText>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={20}>
                  <UISelect
                    value={currentValue[JSON.parse(currentKey)].type_id}
                    //@ts-ignore
                    onSelect={(e): void => handleTypeUpdate(JSON.parse(currentKey), e as string)}
                  >
                    <Select.Option value={"1"}>Upload</Select.Option>
                    <Select.Option value={"2"}>Test</Select.Option>
                  </UISelect>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Divider />
          </Row>
        </Fragment>
      ))}

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <UIText strong>Titolo</UIText>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={20}>
              <UIInput
                placeholder="Titolo"
                value={tempTitle}
                onChange={(e): void => setTempTitle(e.target.value)}
              />
            </Col>
            <Col span={2}>
              {tempType === undefined && (
                <UIButton
                  bgColor={Colors.title}
                  color={Colors.primary}
                  hoverBgColor={Colors.darkBlue}
                  onClick={handleOnAddTempTypeClick}
                >
                  +
                </UIButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {tempType !== undefined && (
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <UIText strong>Tipologia</UIText>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={20}>
                <UISelect
                  onSelect={
                    //@ts-ignore
                    (e): void => setTempType(e as string)
                  }
                >
                  <Select.Option value={"1"}>Upload</Select.Option>
                </UISelect>
              </Col>
              <Col span={2}>
                <UIButton
                  bgColor={Colors.title}
                  color={Colors.primary}
                  hoverBgColor={Colors.darkBlue}
                  onClick={handleOnAddItemClick}
                >
                  +
                </UIButton>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomForm;
