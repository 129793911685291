/** @format */

import React from "react";
import UIRoundIcon from "../ui/RoundIcon";
import { Colors } from "../../theme/maps/Colors";

const RoundIcon = (alt: string, icon: string): JSX.Element => {
  return (
    <UIRoundIcon width="25px" color={Colors.yellow}>
      <img alt={alt} src={icon} style={{ height: "18px" }} />
    </UIRoundIcon>
  );
};

export default RoundIcon;
