/** @format */

import { Button, Dropdown, Menu } from "antd";
import React, { FC, useState } from "react";
import { UIFilePreview } from "../../components/ui/FilePreview";

export const documentListColumns = [
  {
    title: "Titolo",
    dataIndex: "filename",
    key: "filename",
    showMobile: true,
  },
  {
    title: "Documento",
    dataIndex: "document_type_name",
    key: "document_type_name",
    showMobile: true,
  },
  {
    title: "Anno rif",
    dataIndex: "year",
    key: "year",
    showMobile: false,
  },
  {
    title: "Mese rif",
    dataIndex: "month",
    key: "month",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

const OptMenu: FC<{ row: {}; onPreview: () => void }> = ({ row, onPreview }): JSX.Element => {
  /*   //@ts-ignore
  const removeItem = (): TActionDocumentDeleteRequest => dispatch(actionDocumentsDeleteRequest(row.id)); */

  //@ts-ignore
  const removeItem = (): void => console.log("elimino");

  return (
    <Menu>
      {/* <Menu.Item>
        <div onClick={(): void => console.log("d")}>Dettagli</div>
      </Menu.Item> */}
      <Menu.Item>
        <div onClick={onPreview}>Anteprima</div>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            //@ts-ignore
            row.storage_url
          }
        >
          Visualizza
        </a>
      </Menu.Item>
      <Menu.Item>
        <div onClick={removeItem}>Elimina</div>
      </Menu.Item>
    </Menu>
  );
};
//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);

  const showModal = (): void => setVisiblePreview(true);
  const hideModal = (): void => setVisiblePreview(false);
  const options = <OptMenu row={row} onPreview={showModal} />;
  return (
    <>
      <Dropdown overlay={options} placement="bottomRight">
        <Button>...</Button>
      </Dropdown>
      <UIFilePreview pdfUrl={row.storage_url} visible={visiblePreview} onDismiss={hideModal} />
    </>
  );
};
