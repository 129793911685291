/** @format */

import {
  TActionDocumentListRequest,
  TActionDocumentListSuccess,
  TActionDocumentListFailure,
  TActionDocumentCreateRequest,
  TActionDocumentCreateSuccess,
  TActionDocumentCreateFailure,
  TActionDocumentDeleteRequest,
  TActionDocumentDeleteSuccess,
  TActionDocumentDeleteFailure,
  TActionDocumentDeleteReset,
} from "../declarations/actions/document";
import { TActionType, TAction } from "../declarations/actionTypes";
import { TDocument, TDocumentCreateRequest } from "../declarations/maps/documents";

export const actionDocumentListRequest = (): TActionDocumentListRequest => ({
  type: TActionType.documentListRequest,
});

export const actionDocumentListSuccess = (payload: TDocument[]): TActionDocumentListSuccess => ({
  type: TActionType.documentListSuccess,
  payload,
});

export const actionDocumentListFailure = (): TActionDocumentListFailure => ({
  type: TActionType.documentListFailure,
});

export const actionDocumentCreateRequest = (
  payload: TDocumentCreateRequest,
): TActionDocumentCreateRequest => ({ type: TActionType.documentCreateRequest, payload });
export const actionDocumentCreateSuccess = (payload: string): TActionDocumentCreateSuccess => ({
  type: TActionType.documentCreateSuccess,
  payload,
});
export const actionDocumentCreateFailure = (): TActionDocumentCreateFailure => ({
  type: TActionType.documentCreateFailure,
});
export const actionDocumentCreateReset = (): TAction => ({ type: TActionType.documentCreateReset });

export const actionDocumentDeleteRequest = (payload: string): TActionDocumentDeleteRequest => ({
  type: TActionType.documentDeleteRequest,
  payload,
});

export const actionDocumentDeleteSuccess = (payload: string): TActionDocumentDeleteSuccess => ({
  type: TActionType.documentDeleteSuccess,
  payload,
});

export const actionDocumentDeleteFailure = (): TActionDocumentDeleteFailure => ({
  type: TActionType.documentDeleteFailure,
});
export const actionDocumentDeleteReset = (): TActionDocumentDeleteReset => ({
  type: TActionType.documentDeleteReset,
});
