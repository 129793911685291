/** @format */

import { TList } from "../redux-modules/declarations/maps/general";

export interface TObject {
  [key: string]: TObject | string;
}

export const utilityMapObjectArray = <T = object[]>(input: TObject[], objMap: TList<string>): T =>
  //@ts-ignore
  input.map(current => utilityMapObject(current, objMap));

export const utilityMapObject = (input: TObject, objMap: TList<string>): TObject =>
  Object.keys(objMap).reduce(
    (object, currentKey) => ({
      ...object,
      [currentKey]: utilityConcatenateObjectStrings(input, objMap[currentKey].split("|")),
    }),
    {},
  );

export const utilityConcatenateObjectStrings = (input: TObject, dotNotation: string[]): string =>
  dotNotation.reduce(
    (finalString, currentDot) =>
      (finalString + " " + (utilityResolveDotNotation(input, currentDot.split(".")) || "")).trim(),
    "",
  );

export const utilityResolveDotNotation = (
  input: TObject,
  dotNotation: string[],
): string | undefined => {
  if (dotNotation.length === 1) return input[dotNotation[0]] as string;

  if (typeof input[dotNotation[0]] !== "object") return undefined;

  return utilityResolveDotNotation(
    input[dotNotation[0]] as TObject,
    dotNotation.filter((value, index) => index !== 0),
  );
};
