/** @format */

export enum TActionType {
  //region Login

  loginRequest = "[Action] - Create Login Request",
  loginSuccess = "[Event] - Login Success",
  loginFailure = "[Event] - Login Failure",
  loginReset = "[Action] - Login Reset",

  logoutRequest = "[Action] - Create Logout Request",
  logoutSuccess = "[Event] - Logout Success",
  logoutFailure = "[Event] - Logout Failure",

  startCheckStatus = "[Action] - Start Api Key Check",
  successCheckStatus = "[Event] - Success Api Key Check",
  failureCheckStatus = "[Event] - Failure Api Key Check",
  endCheckStatus = "[Event] - End Api Key Check",

  //endregion

  //region Navigation

  navigationRequest = "[Action] - Navigation Request",
  navigationSuccess = "[Action] - Navigation Success",
  navigationFailure = "[Action] - Navigation Failure",

  appLoadingRequest = "[Action] - App Loading Request",
  appLoadingSuccess = "[Action] - App Loading Success",
  appLoadingFailure = "[Action] - App Loading Failure",

  //endregion

  //region Users

  usersListRequest = "[Action] - Users List Request",
  usersListSuccess = "[Action] - Users List Success",
  usersListFailure = "[Action] - Users List Failure",

  userCreateRequest = "[Action] - Users Create Request",
  userCreateSuccess = "[Action] - Users Create Success",
  userCreateFailure = "[Action] - Users Create Failure",
  userCreateReset = "[Action] - Users Create Reset",

  userUpdateRequest = "[Action] - Users Update Request",
  userUpdateSuccess = "[Action] - Users Update Success",
  userUpdateFailure = "[Action] - Users Update Failure",
  userUpdateReset = "[Action] - Users Update Reset",

  userDeleteRequest = "[Action] - Users Delete Request",
  userDeleteSuccess = "[Action] - Users Delete Success",
  userDeleteFailure = "[Action] - Users Delete Failure",
  userDeleteReset = "[Action] - Users Delete Reset",

  userAvatarRequest = "[Action] - Users Avatar Upload Request",
  userAvatarSuccess = "[Action] - Users Avatar Upload Success",
  userAvatarFailure = "[Action] - Users Avatar Upload Failure",
  userAvatarReset = "[Action] - Users Avatar Upload Reset",

  userDocumentUploadRequest = "[Action] - Users Document Upload Request",
  userDocumentUploadSuccess = "[Action] - Users Document Upload Success",
  userDocumentUploadFailure = "[Action] - Users Document Upload Failure",
  userDocumentUploadReset = "[Action] - Users Document Upload Reset",

  usersListLoadingRequest = "[Action] - Loading Users List Request",
  usersListLoadingSuccess = "[Action] - Loading Users List Success",
  usersListLoadingFailure = "[Action] - Loading Users List Failure",

  usersListPageSizeRequest = "[Action] - User List Page Size Change Request",
  usersListPageSizeSuccess = "[Action] - User List Page Size Change Success",
  usersListPageSizeFailure = "[Action] - User List Page Size Change Failure",

  //endregion

  //region Groups

  groupsListRequest = "[Action] - Groups List Request",
  groupsListSuccess = "[Action] - Groups List Success",
  groupsListFailure = "[Action] - Groups List Failure",

  groupCreateRequest = "[Action] - Groups Create Request",
  groupCreateSuccess = "[Action] - Groups Create Success",
  groupCreateFailure = "[Action] - Groups Create Failure",
  groupCreateReset = "[Action] - Groups Create Reset",

  groupUpdateRequest = "[Action] - Groups Update Request",
  groupUpdateSuccess = "[Action] - Groups Update Success",
  groupUpdateFailure = "[Action] - Groups Update Failure",
  groupUpdateReset = "[Action] - Groups Update Reset",

  groupDeleteRequest = "[Action] - Groups Delete Request",
  groupDeleteSuccess = "[Action] - Groups Delete Success",
  groupDeleteFailure = "[Action] - Groups Delete Failure",
  groupDeleteReset = "[Action] - Groups Delete Reset",

  groupsListLoadingRequest = "[Action] - Loading Groups List Request",
  groupsListLoadingSuccess = "[Action] - Loading Groups List Success",
  groupsListLoadingFailure = "[Action] - Loading Groups List Failure",

  //endregion

  //region Location

  locationListRequest = "[Action] - Location List Request",
  locationListSuccess = "[Action] - Location List Success",
  locationListFailure = "[Action] - Location List Failure",

  locationCreateRequest = "[Action] - Location Create Request",
  locationCreateSuccess = "[Action] - Location Create Success",
  locationCreateFailure = "[Action] - Location Create Failure",
  locationCreateReset = "[Action] - Location Create Reset",

  locationUpdateRequest = "[Action] - Location Update Request",
  locationUpdateSuccess = "[Action] - Location Update Success",
  locationUpdateFailure = "[Action] - Location Update Failure",
  locationUpdateReset = "[Action] - Location Update Reset",

  locationDeleteRequest = "[Action] - Location Delete Request",
  locationDeleteSuccess = "[Action] - Location Delete Success",
  locationDeleteFailure = "[Action] - Location Delete Failure",
  locationDeleteReset = "[Action] - Location Delete Reset",

  locationListLoadingRequest = "[Action] - Loading Location List Request",
  locationListLoadingSuccess = "[Action] - Loading Location List Success",
  locationListLoadingFailure = "[Action] - Loading Location List Failure",

  //endregion

  //region Document
  documentListRequest = "[Action] - Document  List Request",
  documentListSuccess = "[Action] - Document  List Success",
  documentListFailure = "[Action] - Document  List Failure",

  documentCreateRequest = "[Action] - Document  Create Request",
  documentCreateSuccess = "[Action] - Document  Create Success",
  documentCreateFailure = "[Action] - Document  Create Failure",
  documentCreateReset = "[Action] - Document  Create Reset",

  documentDeleteRequest = "[Action] - Document  Delete Request",
  documentDeleteSuccess = "[Action] - Document  Delete Success",
  documentDeleteFailure = "[Action] - Document  Delete Failure",
  documentDeleteReset = "[Action] - Document  Delete Reset",

  //endregion

  //region DocumentType
  documentTypeListRequest = "[Action] - Document Type List Request",
  documentTypeListSuccess = "[Action] - Document Type List Success",
  documentTypeListFailure = "[Action] - Document Type List Failure",

  documentTypeCreateRequest = "[Action] - Document Type Create Request",
  documentTypeCreateSuccess = "[Action] - Document Type Create Success",
  documentTypeCreateFailure = "[Action] - Document Type Create Failure",
  documentTypeCreateReset = "[Action] - Document Type Create Reset",

  documentTypeUpdateRequest = "[Action] - Document Type Update Request",
  documentTypeUpdateSuccess = "[Action] - Document Type Update Success",
  documentTypeUpdateFailure = "[Action] - Document Type Update Failure",
  documentTypeUpdateReset = "[Action] - Document Type Update Reset",

  documentTypeDeleteRequest = "[Action] - Document Type Delete Request",
  documentTypeDeleteSuccess = "[Action] - Document Type Delete Success",
  documentTypeDeleteFailure = "[Action] - Document Type Delete Failure",
  documentTypeDeleteReset = "[Action] - Document Type Delete Reset",

  //endregion

  //region Notices

  noticeListRequest = "[Action] - Notice List Request",
  noticeListSuccess = "[Event] - Notice List Success",
  noticeListFailure = "[Event] - Notice List Failure",
  noticeListReset = "[Action] - Notice List Reset",

  noticeCreateRequest = "[Action] - Notice Create Request",
  noticeCreateSuccess = "[Event] - Notice Create Success",
  noticeCreateFailure = "[Event] - Notice Create Failure",
  noticeCreateReset = "[Action] - Notice Create Reset",

  //endregion

  //region Alert
  alertGenerate = "[Event] - Alert Generate",
  alertRemove = "[Event] - Alert Remove",
  //endregion

  //region Static Alert
  staticAlertGenerate = "[Event] - Static Alert Generate",
  staticAlertRemove = "[Event] - Static Alert Remove",
  //endregion

  //region Cache

  cacheSaveSuccess = "[Event] - Save to Local Storage Success",
  cacheSaveFailure = "[Event] - Save to Local Storage Failure",

  cacheRemoveSuccess = "[Event] - Remove from Local Storage Success",
  cacheRemoveFailure = "[Event] - Remove from Local Storage Success",

  //endregion
}

export interface TAction<T = TActionType> {
  type: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}
