/** @format */

import { TAPIAttachment, TAPIAttachmentList } from "../../redux-modules/declarations/apis/general";
import { TAttachment, TAttachmentList } from "../../redux-modules/declarations/maps/general";

export const utilityFormatApiAttachment = (attachment: TAPIAttachment): TAttachment => ({
  ...attachment,
  id: JSON.stringify(attachment.id),
});

export const utilityFormatApiAttachmentList = (list: TAPIAttachmentList): TAttachmentList =>
  list.map(current => utilityFormatApiAttachment(current));
