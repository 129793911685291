/** @format */

import {
  TNotice,
  TNoticeDatasource,
  TNoticeDatasourceList,
  TNoticeList,
} from "../../redux-modules/declarations/maps/notices";
import moment from "moment";

export const utilityFormatNoticeToTableDataSource = ({
  id,
  from,
  to,
  title,
  content,
}: TNotice): TNoticeDatasource => ({
  title: title,
  validity: `dal ${moment(from).format("DD.MM")} al ${moment(to).format("DD.MM")}`,
  actions: id,
  content,
});

export const utilityFormatNoticeToTableDataSourceList = (
  list: TNoticeList,
): TNoticeDatasourceList => list.map(category => utilityFormatNoticeToTableDataSource(category));
