/** @format */

import React from "react";
import { Routes } from "../../utils/Routes";
import { Row, Col } from "antd";
import { UILink } from "../../components/ui/Link";

export const groupListColumns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
  },
  {
    title: "N° Utenti",
    dataIndex: "usersCount",
    key: "usersCount",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => (
  <Row gutter={[12, 0]}>
    <Col>
      <UILink route={Routes.groupsEdit.replace(":id_group", row.id)} label={"Gestisci"} />
    </Col>
  </Row>
);
