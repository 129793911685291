/** @format */

import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Routes } from "../../../../utils/Routes";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { actionLogoutRequest } from "../../../../redux-modules/actions/actionsAuth";
import { useDispatch, useSelector } from "react-redux";
import { TActionLogoutRequest } from "../../../../redux-modules/declarations/actions/auth";
import { selectorLoggedUser } from "../../../../redux-modules/selectors/selectorsAuth";
import NoticeBell from "../../NoticeBell";
import { selectorNoticeList } from "../../../../redux-modules/selectors/selectorsNotices";

const CustomMenu = styled(Menu)`
  float: right;
  .ant-menu-submenu-title {
    color: #dfdfdf !important;
  }
`;

export const UserMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = (): TActionLogoutRequest => dispatch(actionLogoutRequest());
  const user = useSelector(selectorLoggedUser);
  const notices = useSelector(selectorNoticeList);
  console.log(notices);

  return (
    <CustomMenu theme="dark" mode="horizontal">
      <NoticeBell loading={false}>
        <NoticeBell.Tab
          tabKey="notification"
          count={1}
          onClick={(item): void => {
            alert("premuto su " + JSON.stringify(item));
          }}
          list={[
            {
              title: "Caricamento Bustapaga",
              description: "La bustapaga di Gennaio è appena stata caricata sul tuo profilo",
              read: false,
              avatar:
                "https://banner2.cleanpng.com/20190504/yol/kisspng-wikipedia-logo-computer-icons-portable-network-gra-digital-innovation-partnership-enhancing-the-stu-5ccd2a9dcd7286.5903715115569496618415.jpg",
            },
          ]}
          title="Notifiche"
          emptyText="Nessuna notifica"
          showViewMore
        />
      </NoticeBell>
      <SubMenu
        key="user"
        title={
          <>
            <UserOutlined />
            {user?.name}
            <DownOutlined style={{ marginLeft: "5px", fontSize: 12 }} />
          </>
        }
      >
        <Menu.Item>
          <span
            onClick={() => history.push(Routes.usersEdit.replace(":id_user", user ? user.id : ""))}
            //activeClassName="activeNav"
          >
            Profilo
          </span>
        </Menu.Item>
        <Menu.Item onClick={handleLogout}>
          <div>Log Out</div>
        </Menu.Item>
      </SubMenu>
    </CustomMenu>
  );
};
