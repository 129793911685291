/** @format */

import React, { useState } from "react";
import { Col, Form, Row, Steps } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import DefaultFields from "./components/DefaultFields";
import AdditionalFields from "./components/AdditionalFields";

const ViewRequestTypeCreate = (): JSX.Element => {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      step: 1,
      title: "Campi di default",
      content: <DefaultFields />,
    },
    {
      step: 2,
      title: "Campi aggiuntivi",
      content: <AdditionalFields />,
    },
  ];

  const next = (): void => {
    const newCurrent = current + 1;
    setCurrent(newCurrent);
  };

  const prev = (): void => {
    const newCurrent = current - 1;
    setCurrent(newCurrent);
  };

  //@ts-ignore
  const handleSave = (e): void => {
    console.log(e);
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Richieste
          </UITitle>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UICard>
            <Form
              name="new-request"
              className="new-request"
              layout="vertical"
              onFinish={handleSave}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Steps current={current} style={{ marginBottom: "25px" }}>
                    {steps.map(item => (
                      <Steps.Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {steps.map(
                    (item): JSX.Element => (
                      <div
                        key={item.step}
                        className={`steps-content`}
                        style={{ display: item.step !== current + 1 ? "none" : "" }}
                      >
                        {item.content}
                      </div>
                    ),
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="steps-action">
                    {current < steps.length - 1 && (
                      <UIButton type="primary" onClick={next}>
                        Avanti
                      </UIButton>
                    )}
                    {current === steps.length - 1 && (
                      <Form.Item style={{ display: "inline-block", float: "right" }}>
                        <UIButton type="primary" htmlType="submit">
                          Crea nuova richiesta
                        </UIButton>
                      </Form.Item>
                    )}
                    {current > 0 && <UIButton onClick={prev}>Indietro</UIButton>}
                  </div>
                </Col>
              </Row>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};

export default ViewRequestTypeCreate;
