/** @format */

import React, { useState } from "react";
import { Col, Form, Row, Select, Switch } from "antd";
import { UIText } from "../../../ui/Text";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
import { UIDatePicker } from "../../../ui/DatePicker";

const DefaultFields = (): JSX.Element => {
  const [monthlyAccumulation, setMonthlyAccumulation] = useState(false);
  const [monthlyRemaining, setMonthlyRemaining] = useState(false);

  const [disableHoursAccumulation, setDisableHoursAccumulation] = useState(false);
  const [disableAnnualAccumulation, setDisableAnnualAccumulation] = useState(false);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="title"
            label={<UIText strong={true}>Titolo</UIText>}
            rules={[{ required: true, message: "Inserisci un titolo!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="subtype" label={<UIText strong={true}>Sotto Tipologia</UIText>}>
            <UIInput />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="period"
            initialValue={1}
            label={<UIText strong={true}>Periodo</UIText>}
            rules={[{ required: true, message: "Inserisci un periodo!" }]}
          >
            <UISelect>
              <Select.Option value={1}>Tutto il giorno</Select.Option>
              <Select.Option value={2}>Periodo</Select.Option>
              <Select.Option value={3}>Orari</Select.Option>
            </UISelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unit"
            initialValue={1}
            label={<UIText strong={true}>Unità di misura</UIText>}
            rules={[{ required: true, message: "Inserisci un unità di misura!" }]}
          >
            <UISelect>
              <Select.Option value={1}>Ore</Select.Option>
              <Select.Option value={2}>Giorni</Select.Option>
            </UISelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="mindays" label={<UIText strong={true}>Min. giorni consecutivi</UIText>}>
            <UIInput type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="maxdays" label={<UIText strong={true}>Min. giorni consecutivi</UIText>}>
            <UIInput type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="defaultState"
            initialValue={1}
            label={<UIText strong={true}>Stato di default</UIText>}
          >
            <UISelect>
              <Select.Option value={1}>Da Validare</Select.Option>
              <Select.Option value={2}>Accettata</Select.Option>
            </UISelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} />
        <Col span={12}>{monthlyAccumulation && <UIText strong={true}>Valore</UIText>}</Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={21}>
              <UIText strong={true}>Maturazione mensile</UIText>
            </Col>
            <Col span={3}>
              <Form.Item name="monthlyAccumulation" valuePropName="checked" noStyle>
                <Switch onChange={setMonthlyAccumulation} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {monthlyAccumulation && (
            <>
              <Row gutter={[16, 16]} justify="center" align="middle">
                <Col span={12}>
                  <Row gutter={[16, 8]}>
                    <Col span={16}>
                      <Form.Item name="hoursAccumulationValues" noStyle>
                        <UIInput
                          disabled={disableHoursAccumulation}
                          type="number"
                          placeholder="- - -"
                          onChange={(event): void =>
                            setDisableAnnualAccumulation(!!event.target?.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <UIText strong={true}>Mensile</UIText>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[16, 8]}>
                    <Col span={16}>
                      <Form.Item name="annualAccumulationValues" noStyle>
                        <UIInput
                          disabled={disableAnnualAccumulation}
                          type="number"
                          placeholder="- - -"
                          onChange={(event): void =>
                            setDisableHoursAccumulation(!!event.target?.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <UIText strong={true}>Annuale</UIText>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} />
        <Col span={12}>
          {monthlyRemaining && <UIText strong={true}>Giorno e mese di scadenza</UIText>}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={21}>
              <UIText strong={true}>Residuo mensile</UIText>
            </Col>
            <Col span={3}>
              <Form.Item name="monthlyRemaining" valuePropName="checked" noStyle>
                <Switch onChange={setMonthlyRemaining} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          {monthlyRemaining && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={10}>
                  <Form.Item name="expirationDate" noStyle>
                    <UIDatePicker format={"DD/MM"} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DefaultFields;
