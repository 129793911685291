/** @format */

import { Row, Col, Select, Form, Skeleton } from "antd";
/* import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
 */ import React, { FC } from "react";
/* import { UIInput } from "../../../ui/Input";
 */ import { UISelect } from "../../../ui/Select";
/* import { UIDatePicker } from "../../../ui/DatePicker";
import { UIText } from "../../../ui/Text"; */
const { Option } = Select;

interface Props {
  groups: [];
  locations: [];
}

export const ProfessionalInfoTab: FC<Props> = ({ groups, locations }: Props): JSX.Element => {
  return (
    <>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="location"
            label="Sede predefinita"
            extra="seleziona la sede dell'utente"
            rules={[{ required: true, message: "Inserisci la sede dell'utente!" }]}
          >
            <UISelect>
              {locations.map(location => (
                //@ts-ignore
                <Option key={location.id} value={location.id}>
                  {
                    //@ts-ignore
                    location.name
                  }
                </Option>
              ))}
            </UISelect>
          </Form.Item>
        </Col>
        {/* <Col md={12}>
          <Form.Item
            name="workEmail"
            label="Email Aziendale"
            rules={[{ required: false, message: "Inserisci l'email aziendale dell'utente!" }]}
            extra="Può coincidere con quella personale"
          >
            <UIInput type="email" />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          {!groups.length && <Skeleton.Input active={true} size={"default"} />}
          {!!groups.length && (
            <Form.Item
              name="group"
              label="Gruppo"
              extra="seleziona il gruppo dell'utente"
              rules={[{ required: true, message: "Inserisci il gruppo  dell'utente!" }]}
            >
              <UISelect>
                {groups.map(group => (
                  //@ts-ignore
                  <Option key={group.id} value={group.id}>
                    {
                      //@ts-ignore
                      group.name
                    }
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          )}
        </Col>
      </Row>
      {/*  <Row gutter={[12, 0]}>
        <Col md={8}>
          <Form.Item
            name="pinCode"
            label="Pin Code"
            rules={[{ required: false, message: "Inserisci l'email aziendale dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="qrCode"
            label="Qr Code"
            rules={[{ required: false, message: "Inserisci l'email aziendale dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name="intNumberPhone"
            label="Numero Interno"
            rules={[{ required: false, message: "Inserisci l'email aziendale dell'utente!" }]}
          >
            <UIInput type="number" />
          </Form.Item>
        </Col>
      </Row> */}
      {/* <Row gutter={[12, 0]}>
        <Col md={24}>
          <UIText>Rapporto di lavoro</UIText>
          <Form.List name="relationship">
            {(fields, { add, remove }): JSX.Element => {
              return (
                <div style={{ marginTop: "10px" }}>
                  {fields.map(field => (
                    <Row key={field.key}>
                      <Col
                        xs={22}
                        style={{
                          backgroundColor: "#8080801f",
                          borderRadius: "10px",
                          padding: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <Row gutter={[12, 0]}>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "start"]}
                              fieldKey={[field.fieldKey, "start"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Data Inizio"
                            >
                              <UIDatePicker format={"DD/MM/YYYY"} />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "stop"]}
                              fieldKey={[field.fieldKey, "stop"]}
                              rules={[{ required: false, message: "Missing last name" }]}
                              label="Data Fine"
                            >
                              <UIDatePicker format={"DD/MM/YYYY"} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "type"]}
                              fieldKey={[field.fieldKey, "type"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Tipo Rapporto"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "classification"]}
                              fieldKey={[field.fieldKey, "classification"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Inquadramento"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                          <Col xs={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, "level"]}
                              fieldKey={[field.fieldKey, "level"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Livello"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, "variation"]}
                              fieldKey={[field.fieldKey, "variation"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Variazione"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                          <Col xs={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, "qualification"]}
                              fieldKey={[field.fieldKey, "qualification"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Qualifica"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[12, 0]}>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "weekleyHours"]}
                              fieldKey={[field.fieldKey, "weeklyHours"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Ore/Settimana"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                          <Col xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "swWeekleyHours"]}
                              fieldKey={[field.fieldKey, "swWeekleyHours"]}
                              rules={[{ required: false, message: "Missing first name" }]}
                              label="Ore/Settimana SmartWorking"
                            >
                              <UIInput />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2} style={{ display: "inline-grid" }}>
                        <Row justify="center" align="middle">
                          <MinusCircleOutlined
                            onClick={(): void => {
                              remove(field.name);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ borderRadius: "10px" }}
                      onClick={(): void => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />
                      Inserisci Rapporto Lavorativo
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Col>
      </Row> */}
    </>
  );
};
