/** @format */

import apiClient from "../../utils/apiClient";
import { API_ENDPOINTS } from "../../constants/api";
import { TNoticeListRequest } from "../../redux-modules/declarations/maps/notices";
import { AxiosResponse } from "axios";
import { TAPINoticeList } from "../../redux-modules/declarations/apis/notices";

export const apiNoticeList = (params: TNoticeListRequest): Promise<AxiosResponse<TAPINoticeList>> =>
  apiClient.get(API_ENDPOINTS.NOTICES, { params });
