/** @format */

import React, { useEffect } from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { Routes } from "../../../../../utils/Routes";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { actionDocumentTypeCreateRequest } from "../../../../../redux-modules/actions/actionsDocumentType";
import { selectorDocumentTypeCreated } from "../../../../../redux-modules/selectors/selectorsUI";
import { UIButton } from "../../../../ui/Button";
import { UICard } from "../../../../ui/Card";
import { UIInput } from "../../../../ui/Input";
import { UITitle } from "../../../../ui/Title";
import { UILink } from "../../../../ui/Link";

export const ViewDocumentTypeCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isDocumentTypeCreated = useSelector(selectorDocumentTypeCreated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      split: e.split || false,
    };
    //dispatch
    console.log(contentObj);
    dispatch(actionDocumentTypeCreateRequest(contentObj));
  };

  useEffect(() => {
    if (isDocumentTypeCreated) history.push(Routes.settings + SETTINGSTABS.DOCUMENT_TYPES);
  }, [isDocumentTypeCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={4}>Nuova Tipologia Documento</UITitle>
        </Col>
      </Row>
      <Form
        name="new-document-type"
        className="new-document-type"
        onFinish={handleSave}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome della tipologia"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="split"
                    valuePropName="checked"
                    noStyle
                    rules={[{ required: false, message: "Seleziona un valore" }]}
                    extra={`Il documento deve essere splittato`}
                  >
                    <Checkbox>Split</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
        <Row justify="end" gutter={[24, 24]}>
          <Col>
            <UILink
              route={Routes.settings + SETTINGSTABS.DOCUMENT_TYPES}
              label={<UIButton>Annulla</UIButton>}
            />
          </Col>
          <Col>
            <Form.Item>
              <UIButton type="primary" htmlType="submit">
                + Crea Tipologia Documento
              </UIButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
