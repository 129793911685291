/** @format */

import React, { FC } from "react";
import Dragger from "antd/lib/upload/Dragger";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";

interface Props {
  onChange?: (items: UploadFile[]) => void;
  children?: React.ReactNode | React.ReactNodeArray;
  multiple?: boolean;
}

const UIFormItemDragger: FC<Props> = ({ onChange, children, multiple }): JSX.Element => {
  const handleOnChange = (e: UploadChangeParam): void => {
    if (onChange) onChange(e.fileList);
  };

  return (
    <Dragger beforeUpload={(): boolean => false} onChange={handleOnChange} multiple={multiple}>
      {children}
    </Dragger>
  );
};

export default UIFormItemDragger;
