/** @format */

import { Col, Form, Row, Select, Skeleton } from "antd";

import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { actionUserAvatarRequest } from "../../../../redux-modules/actions/actionsUsers";
import { UIImageUploader } from "../../../ui/ImageUploader";
import { UIInput } from "../../../ui/Input";
import { UISelect } from "../../../ui/Select";
/* import avatarPlaceholder from "../../../../assets/images/profilePicturePlaceholder.png";
 */ import { UICard } from "../../../ui/Card";

interface Props {
  roles: [];
  avatar?: string;
}

export const PersonalInfoTabEdit: FC<Props> = ({ roles, avatar }: Props): JSX.Element => {
  //@ts-ignore
  const { id_user } = useParams();
  const dispatch = useDispatch();

  const onUpload = (fileList: FileList): void => {
    const avatarObj = {
      id: id_user,
      avatar: fileList[0],
    };
    console.log(avatarObj);
    dispatch(actionUserAvatarRequest(avatarObj));
  };

  // TODO togliere "avatar" default quando non da più errore
  const [onUseAvatar /* setOnUseAvatar */] = useState<string | undefined>(avatar);
  /*   useEffect(() => { // TODO togliere commento quando non da più errore CORS
    if (avatar) {
      fetch(avatar)
        .then(res => res.status)
        .then(status => {
          console.log(status);
          if (status !== 200) {
            setOnUseAvatar(avatarPlaceholder);
            console.log(onUseAvatar);
          } else {
            setOnUseAvatar(avatar);
          }
        });
    } else {
      setOnUseAvatar(avatarPlaceholder);
    }
  }, []); */

  // TODO commentare quando non da più errore CORS

  return (
    <>
      {!!!onUseAvatar ? (
        <UICard style={{ marginBottom: "20px" }}>
          <Skeleton active />
        </UICard>
      ) : (
        <Row>
          <Col xs={24} md={4}>
            <UIImageUploader defaultImage={onUseAvatar} handleUpload={onUpload} />
          </Col>
          <Col xs={24} md={20}>
            <Row gutter={[12, 0]}>
              <Col md={12}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: "Inserisci un nome!" }]}
                  extra="Il nome dell'utente"
                >
                  <UIInput />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="surname"
                  label="Cognome"
                  rules={[{ required: true, message: "Inserisci un cognome!" }]}
                  extra="Il cognome dell'utente"
                >
                  <UIInput />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col md={16}>
                <Form.Item
                  name="fiscal_code"
                  label="Codice Fiscale"
                  rules={[{ required: true, message: "Inserisci il codice fiscale dell'utente!" }]}
                  extra="Il codice fiscale dell'utente"
                >
                  <UIInput />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="role"
                  label="Ruolo"
                  rules={[{ required: true, message: "Seleziona il ruolo dell'utente!" }]}
                  extra="Il ruolo dell'utente nella piattaforma"
                >
                  <UISelect>
                    {roles?.map(role => (
                      //@ts-ignore
                      <Select.Option key={role.id} value={role.id}>
                        {
                          //@ts-ignore
                          role.name
                        }
                      </Select.Option>
                    ))}
                  </UISelect>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col md={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Inserisci l'email dell'utente!" }]}
                  extra="Indirizzo email dell'utente"
                >
                  <UIInput type="email" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="phone"
            label="Telefono 1"
            rules={[{ required: true, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di telefono dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="phone2"
            label="Telefono 2"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di telefono dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
          {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="mobile"
            label="Mobile 1"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di cellulare dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="mobile2"
            label="Mobile 2"
            rules={[{ required: false, message: "Inserisci almeno un numero di telefono!" }]}
            extra="Numero di cellulare dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
          {/* <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="address"
            label="Indirizzo"
            rules={[{ required: true, message: "Inserisci l'indirizzo dell'utente!" }]}
            extra="Indirizzo dell'utente"
          >
            <UIInput />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            name="cap"
            label="CAP"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item
            name="city"
            label="Località"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name="state"
            label="Provincia"
            rules={[{ required: false, message: "Inserisci l'indirizzo dell'utente!" }]}
          >
            <UIInput />
          </Form.Item>
        </Col>
      </Row> */}
        </Row>
      )}
    </>
  );
};
