/** @format */
import NoticeListColumnsRenderer from "../../components/renderers/NoticeTableMoreActions";
import { ColumnsType } from "antd/lib/table";
import bell from "../../assets/svg/bell.svg";
import RoundIcon from "../../components/renderers/RoundIcon";

export const noticeListColumns: ColumnsType<object> = [
  {
    title: " ",
    dataIndex: "icon",
    key: "icon",
    render: (): JSX.Element => RoundIcon("notice", bell),
  },
  {
    title: "Titolo Avviso",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Validità",
    dataIndex: "validity",
    key: "validity",
  },
  {
    align: "center",
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    render: NoticeListColumnsRenderer,
  },
];
