/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../../../ui/Title";
import { useDispatch, useSelector } from "react-redux";
import { actionDocumentTypeListRequest } from "../../../../../redux-modules/actions/actionsDocumentType";
import { selectorDocumentTypesList } from "../../../../../redux-modules/selectors/selectorsDocumentTypes";
import { UITable } from "../../../../ui/Table";
import { documentTypeColumns } from "../../../../../constants/tables/documentTypeColumns";
import { UIButton } from "../../../../ui/Button";
import { Routes } from "../../../../../utils/Routes";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { useHistory, useLocation } from "react-router";
import { ViewDocumentTypeCreate } from "./ViewDocumentTypeCreate";
import { ViewDocumentTypeEdit } from "./ViewDocumentTypeEdit";
import { selectorDocumentTypeDeleted } from "../../../../../redux-modules/selectors/selectorsUI";
import UITransition from "../../../../ui/Transition";

export const DocumentTypesTab = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [documentTypes, setDocumentTypes] = useState([]);
  const dispatch = useDispatch();
  const documentTypeDeleted = useSelector(selectorDocumentTypeDeleted);

  const documentTypeList = useSelector(selectorDocumentTypesList);

  useEffect(() => {
    if (location.pathname === Routes.settings + SETTINGSTABS.DOCUMENT_TYPES) {
      dispatch(actionDocumentTypeListRequest());
    }
  }, [location.pathname, documentTypeDeleted]);

  useEffect(() => {
    //@ts-ignore
    if (documentTypeList) setDocumentTypes(documentTypeList);
  }, [documentTypeList]);

  switch (location.pathname) {
    case Routes.settings + SETTINGSTABS.DOCUMENT_TYPES + "/nuovo":
      return <ViewDocumentTypeCreate />;
    case Routes.settings + SETTINGSTABS.DOCUMENT_TYPES:
      return (
        <UITransition>
          <Row justify="space-between" align="top" style={{ marginBottom: "10px" }}>
            <Col style={{ paddingTop: "5px" }}>
              <UITitle level={4}>Tipologia Documenti</UITitle>
            </Col>
            <Col>
              <UIButton
                onClick={(): void =>
                  history.push(Routes.settings + SETTINGSTABS.DOCUMENT_TYPES + "/nuovo")
                }
                type="primary"
              >
                Crea Nuova
              </UIButton>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <UITable
                columns={documentTypeColumns}
                dataSource={documentTypes}
                loading={!documentTypes}
                pagination={false}
                size="middle"
              />
            </Col>
          </Row>
        </UITransition>
      );
    default:
      return <ViewDocumentTypeEdit />;
  }
};
