/** @format */

import { Row, Col, Image } from "antd";
import React, { FC } from "react";
import { UITitle } from "../../../../ui/Title";
import Logo from "../../../../../assets/svg/logo.svg";
import Text from "antd/lib/typography/Text";
// @ts-ignore
import pkgJson from "../../../../../constants/package.alias.json";

export const GeneralInfoTab: FC = (): JSX.Element => {
  return (
    <>
      <Row justify="space-between">
        <Col>
          <UITitle level={4}>Informazioni Generali</UITitle>
        </Col>
      </Row>
      <Row justify="center" gutter={[12, 8]}>
        <Col>
          <Image width={160} src={Logo} />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <UITitle style={{ textAlign: "center" }}>
            {process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME.toUpperCase() : "EASYHR"}
          </UITitle>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Text>Versione {pkgJson.version}</Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Text>
            Copyright © {new Date().getFullYear()} URIOS - divisione di Informatica Commerciale
          </Text>
        </Col>
      </Row>
    </>
  );
};
