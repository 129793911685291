/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row, Form, TreeSelect } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { CloseOutlined } from "@ant-design/icons";
import { UIText } from "../../ui/Text";
import { UIInput } from "../../ui/Input";
import { UITextArea } from "../../ui/Textarea";
import { UIRangePicker } from "../../ui/RangePicker";
import { useOptionList } from "../../../redux-modules/hooks/useOptionList";
import { API_ENDPOINTS } from "../../../constants/api";
import { groupToEntryMap } from "../../../constants/maps/GroupToEntryMap";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { UIButton } from "../../ui/Button";
import { UploadFile } from "antd/lib/upload/interface";
import { actionNoticeCreateRequest } from "../../../redux-modules/actions/actionsNotices";
import { useDispatch, useSelector } from "react-redux";
import { selectorNoticeCreateStatus } from "../../../redux-modules/selectors/selectorsUI";
import { useRequestStatus } from "../../../redux-modules/hooks/useRequestStatus";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UITreeSelect } from "../../ui/TreeSelect";
import { useTreeOptionList } from "../../../redux-modules/hooks/useTreeOptionList";
import { userToEntryMap } from "../../../constants/maps/UserToEntryMap";
import { UICard } from "../../ui/Card";
const { SHOW_CHILD } = TreeSelect;

const ViewNoticeCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [debouncedQuery, , setQuery] = useDebouncedInput("");
  const [groupList] = useOptionList(API_ENDPOINTS.GROUPS, undefined, groupToEntryMap);
  const [recipientList, isLoading, , setOptionListParams] = useTreeOptionList(
    API_ENDPOINTS.USERS_LIST,
    userToEntryMap,
    groupList,
  );

  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const requestStatus = useSelector(selectorNoticeCreateStatus);
  const { isPending, hasSucceded } = useRequestStatus(requestStatus);

  const handleUpload = (e: UploadChangeParam): void => {
    if (e.fileList) setAttachments(e.fileList);
    else setAttachments([]);
  };

  useEffect(() => {
    setOptionListParams({ query: debouncedQuery });
  }, [debouncedQuery]);

  useEffect(() => {
    if (hasSucceded) history.push(Routes.notices);
  }, [hasSucceded]);

  //@ts-ignore
  const handleSave = (e): void => {
    const obj = {
      title: e.title,
      from: e.validity[0] ? e.validity[0].format("YYYY-MM-DD") : undefined,
      to: e.validity[1] ? e.validity[1].format("YYYY-MM-DD") : undefined,
      content: e.content,
      user_ids: e.recipients.map((current: string) => current.split("-")[1]),
      attachments: e.attachments
        ? e.attachments.fileList.map((current: UploadFile) => current.originFileObj as File)
        : undefined,
    };

    dispatch(actionNoticeCreateRequest(obj));
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col span={23}>
          <UITitle level={2} color={Colors.title}>
            Nuovo Avviso
          </UITitle>
        </Col>
        <Col span={1}>
          <div
            style={{
              height: "100%",
              cursor: "pointer",
              textAlign: "center",
              fontSize: "24px",
              color: Colors.darkGray,
            }}
            onClick={(): void => history.goBack()}
          >
            <CloseOutlined style={{ height: "100%" }} />
          </div>
        </Col>
      </Row>
      <Form name="new-notice" className="new-notice" layout="vertical" onFinish={handleSave}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UICard loading={isPending}>
              <Row gutter={[24, 8]}>
                <Col span={16}>
                  <Form.Item
                    name="title"
                    label={
                      <>
                        <Row gutter={8}>
                          <Col>
                            <UIText strong={true}>Titolo</UIText>
                          </Col>
                          <Col>
                            <UIText color={Colors.darkGray}>(Max 40 caratteri)</UIText>
                          </Col>
                        </Row>
                      </>
                    }
                    rules={[{ required: true, message: "Inserisci un titolo!" }]}
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="validity"
                        label={<UIText strong={true}>Validità</UIText>}
                        rules={[{ required: true, message: "Inserisci delle date!" }]}
                      >
                        <UIRangePicker format={"DD/MM/YYYY"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="content"
                    label={<UIText strong={true}>Contenuto</UIText>}
                    rules={[{ required: true, message: "Inserisci un contenuto!" }]}
                  >
                    <UITextArea rows={8} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Form.Item
                    name="recipients"
                    label={<UIText strong={true}>Destinatari</UIText>}
                    rules={[{ required: true, message: "Inserisci un destinatario!" }]}
                  >
                    <UITreeSelect
                      treeData={recipientList}
                      treeCheckable
                      showSearch
                      showCheckedStrategy={SHOW_CHILD}
                      onSearch={setQuery}
                      loading={isLoading}
                      filterTreeNode={(inputValue, treeNode): boolean =>
                        !!treeNode &&
                        !!treeNode.title &&
                        (treeNode.title as string)
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Form.Item
                    name="attachments"
                    label={
                      <>
                        <Row gutter={8}>
                          <Col>
                            <UIText strong={true}>Inserisci un allegato</UIText>
                          </Col>
                          <Col>
                            <UIText color={Colors.darkGray}>(formati possibili: pdf, jpg)</UIText>
                          </Col>
                        </Row>
                      </>
                    }
                  >
                    <Upload
                      accept={"application/pdf, image/jpg"}
                      multiple
                      beforeUpload={(): boolean => false}
                      fileList={attachments ? attachments : undefined}
                      onChange={handleUpload}
                    >
                      <UIButton type="primary">Allega</UIButton>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" align="bottom" gutter={[12, 12]}>
                <Col>
                  <Form.Item noStyle>
                    <UIButton style={{ width: "100%" }} type="primary" htmlType="submit">
                      Crea Avviso
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ViewNoticeCreate;
