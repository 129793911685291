/** @format */

import React from "react";
import { Col, Row } from "antd";
import { UICard } from "../../ui/Card";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { DocumentTypesTab } from "./tabs/DocumentTypes";
import RoutedTabs, { RouteConfigObject } from "../../ui/RoutedTabs";
import { TabsProps } from "antd/lib/tabs";
import { SETTINGSTABS } from "../../../constants/settings";
import { GeneralInfoTab } from "./tabs/GeneralInfo";
import { RequestTypesTab } from "./tabs/RequestTypes";

/* const Test: FC = (): JSX.Element => (
  <>
    <p>Testetstetsetst</p>
  </>
); */

export const ViewSettings = (): JSX.Element => {
  const routeTabsConfig: RouteConfigObject = {
    "tipologia-richiesta": {
      label: "Tipologia Richieste",
      component: RequestTypesTab,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.REQUEST_TYPES}`,
    },
    "tipologia-documento": {
      label: "Tipologia Documenti",
      component: DocumentTypesTab,
      getRoute: (url: string): string => `${url}${SETTINGSTABS.DOCUMENT_TYPES}`,
    },
    "/": {
      label: "Informazioni Generali",
      component: GeneralInfoTab,
      getRoute: (url: string): string => url,
    },
    /* shiftType: {
      label: "Tipologia Turni",
      component: Test,
      getRoute: (url: string): string => `${url}/shiftsType`,
    }, */
  };

  const tabsProps: TabsProps = {
    tabPosition: "left",
  };

  return (
    <>
      <Row>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Configurazione
          </UITitle>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <UICard>
            <RoutedTabs
              tabsProps={tabsProps}
              routeConfig={routeTabsConfig}
              basePath="/configurazione"
            />
          </UICard>
        </Col>
      </Row>
    </>
  );
};
