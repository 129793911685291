/** @format */

import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Routes } from "../../../utils/Routes";

interface Props {
  route: Routes | string;
  label: string | JSX.Element;
}

export const UILink = ({ route, label }: Props): JSX.Element => {
  const history = useHistory();
  return <StyledLink onClick={() => history.push(route)}>{label}</StyledLink>;
};

const StyledLink = styled.span`
  cursor: pointer;
  color: #1052cc;
`;
