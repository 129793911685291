/** @format */

import { TAPIEntry, TAPIEntryList } from "../../redux-modules/declarations/apis/general";
import { TEntry, TEntryList } from "../../redux-modules/declarations/maps/general";

export const utilityFormatApiEntry = (entry: TAPIEntry): TEntry => ({
  ...entry,
  id: JSON.stringify(entry.id),
});

export const utilityFormatApiEntryList = (list: TAPIEntryList): TEntryList =>
  list.map(current => utilityFormatApiEntry(current));
