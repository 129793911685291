/** @format */

import React from "react";

const NoticeExpand = (record: object): JSX.Element => {
  //@ts-ignore
  return <p>{record.content}</p>;
};

export default NoticeExpand;
