/** @format */

import styled from "styled-components";
import React, { FC } from "react";
import addButton from "../../../../assets/svg/addPhoto.svg";

const Input = styled.input.attrs({
  type: "file",
})`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface Props {
  multiple?: boolean;
  accept?: string;
  handleFiles: (e: FileList) => void;
}

export const AddImageInput: FC<Props> = ({
  handleFiles,
  multiple = false,
  accept,
}): JSX.Element => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (): void | false =>
    hiddenFileInput.current ? hiddenFileInput.current.click() : false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileUploaded = e.target.files;
    if (fileUploaded) {
      handleFiles(fileUploaded);
    }
  };

  return (
    <div
      style={{
        display: "inline-block",
        position: "absolute",
        right: "0px",
        bottom: "0px",
        zIndex: 9999,
      }}
    >
      <Input ref={hiddenFileInput} multiple={multiple} accept={accept} onChange={handleChange} />
      <div onClick={handleClick}>
        <img src={addButton} width="42px" />
      </div>
    </div>
  );
};
