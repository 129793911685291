/** @format */

import moment from "moment";

export interface TUserDocument {
  created_at: string;
  document_type: object;
  filename: string;
  id: string;
  storage_url: string;
  valid_at: string;
}

interface TUserDocumentFormatted {
  created_at: string;
  type: string;
  filename: string;
  id: string;
  storage_url: string;
  valid_at: string;
}
export const utilityFormatUserDocumentsToTableDataSource = (
  doc: TUserDocument,
): TUserDocumentFormatted => ({
  ...doc,
  //@ts-ignore
  type: doc.document_type.name,
  valid_at: moment(doc.valid_at).format("DD/MM/YYYY"),
});

export const utilityFormatUserDocumentsToTableDataSourceList = (
  list: TUserDocument[],
): TUserDocumentFormatted[] => list.map(doc => utilityFormatUserDocumentsToTableDataSource(doc));
