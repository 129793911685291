/** @format */

import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends TableProps<any> {
  backgroundColor?: string;
}

export const UITable = (props: Props): JSX.Element => {
  return (
    <StyledTable
      {...props}
      //@ts-ignore
      columns={isMobileOnly ? props.columns?.filter(e => e.showMobile) : props.columns}
    />
  );
};

const StyledTable = styled(Table)<Props>`
  border-radius: 7px;
  border: 1px solid #cfcfcf;
  background-color: ${(props): string => props.backgroundColor || "white"} !important;
  tr .ant-table-cell {
    background-color: ${(props): string => props.backgroundColor || "white"} !important;
  }

  .ant-table-thead > tr > th {
    background-color: #dee8f3 !important;
    border-bottom: ${(props): string =>
      props.backgroundColor ? "1px solid #cfcfcf" : "1px solid #f0f0f0"} !important;
  }

  .ant-pagination {
    padding: 0px 24px 0px 24px;
  }

  thead {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  tbody {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-table-tbody {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
  .ant-table {
    border-radius: 7px !important;
  }

  .ant-table-placeholder {
    border-radius: 7px !important;
  }
  .ant-table-placeholder:hover {
    border-radius: 7px !important;
  }
  .ant-table table {
    border-radius: 7px !important;
  }
  .ant-table-content {
    border-radius: 7px !important;
  }
  .ant-table-container {
    border-radius: 7px !important;
  }
  thead > tr > th:nth-child(1) {
    border-top-left-radius: 7px !important;
  }
  thead > tr > th:last-child {
    border-top-right-radius: 7px !important;
  }

  ${(props): string =>
    !props.pagination
      ? `tr:last-child > td {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  };`
      : ""}

  tbody > td > th:last-child {
    border-bottom-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
  }
`;
/* export const UITable = styled(Table)<Props>`
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  background-color: ${(props): string => props.backgroundColor || "white"} !important;
  tr .ant-table-cell {
    background-color: ${(props): string => props.backgroundColor || "white"} !important;
  }

  thead {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  tbody {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .ant-table-tbody {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .ant-table {
    border-radius: 10px !important;
  }
  td.ant-table-cell {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .ant-table-placeholder {
    border-radius: 10px !important;
  }
  .ant-table-placeholder:hover {
    border-radius: 10px !important;
  }
  .ant-table table {
    border-radius: 10px !important;
  }
  .ant-table-content {
    border-radius: 10px !important;
  }
  .ant-table-container {
    border-radius: 10px !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: ${(props): string =>
      props.backgroundColor ? "1px solid #cfcfcf" : "1px solid #f0f0f0"} !important;
  }

  thead > tr > th:nth-child(1) {
    border-top-left-radius: 10px !important;
  }
  thead > tr > th:last-child {
    border-top-right-radius: 10px !important;
  }
  tbody > td > th:last-child {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
`;
 */
