/** @format */

import { Col, Layout, Menu, Row } from "antd";
import {
  UsergroupAddOutlined,
  FileOutlined,
  HomeOutlined,
  ShopOutlined,
  ToolOutlined,
  BellOutlined,
  CheckSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import "./App.less";
import Theme from "./theme/Theme";
import { KEY_APP_NAME } from "./constants/general";
import appLogo from "./assets/svg/logoWhite.svg";
import Title from "antd/lib/typography/Title";
import styled from "styled-components";
import { Routes } from "./utils/Routes";
import { UserMenu } from "./components/layout/Header/UserMenu";
import { RenderRouteLogged } from "./components/utils/RouteLogged";
import { RenderRouteGuest } from "./components/utils/RouteGuest";
import { ViewLogin } from "./components/view/ViewLogin";
import { useDispatch, useSelector } from "react-redux";
import { selectorAuthenticated } from "./redux-modules/selectors/selectorsAuth";
import { UINavLinkItem } from "./components/ui/NavLinkItem";
import { ViewForgotPassword } from "./components/view/ViewForgotPassword";
import ViewNoticeCreate from "./components/view/ViewNoticeCreate";
import moment from "moment";
import ViewRequestList from "./components/view/ViewRequestList";
import ViewRequestTypeCreate from "./components/view/ViewRequestTypeCreate";
import { actionStartApiKeyCheck } from "./redux-modules/actions/actionsAuth";
import { ViewNoticeList } from "./components/view/ViewNoticeList";
import { ViewLocationEdit } from "./components/view/ViewLocationEdit";
import { ViewGroupEdit } from "./components/view/ViewGroupEdit";
import { ViewUserEdit } from "./components/view/ViewUserEdit";
import { ViewGroupCreate } from "./components/view/ViewGroupCreate";
import { ViewLocationCreate } from "./components/view/ViewLocationCreate";
import { ViewUserCreate } from "./components/view/ViewUserCreate";
import { ViewSettings } from "./components/view/ViewSettings";
import { ViewDocumentList } from "./components/view/ViewDocumentList";
import { ViewLocationList } from "./components/view/ViewLocationList";
import { ViewGroupList } from "./components/view/ViewGroupList";
import { ViewHome } from "./components/view/ViewHome";
import { ViewUserList } from "./components/view/ViewUserList";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { UILink } from "./components/ui/Link";

const { Header, Content, Sider } = Layout;

const App = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = useSelector(selectorAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    moment().locale("it");
    dispatch(actionStartApiKeyCheck());
  }, []);

  return (
    <Theme>
      <Layout style={{ height: "100%" }}>
        <Header
          className="header"
          style={{ display: isAuthenticated ? "" : "none", padding: "0 5px 0 20px" }}
        >
          <Row className="justify-content-between">
            <Col span={16}>
              <UILink
                route={Routes.home}
                label={
                  <Row align="bottom">
                    <Col>
                      <img src={appLogo}></img>
                    </Col>
                    <Col>
                      <CustomLogo level={3} className="logo">
                        {KEY_APP_NAME}
                      </CustomLogo>
                    </Col>
                  </Row>
                }
              />
            </Col>
            <Col span={8}>
              <UserMenu />
            </Col>
          </Row>
        </Header>
        <Layout>
          <Sider
            width={200}
            className="site-layout-background"
            style={{ display: isAuthenticated ? "" : "none" }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["/"]}
              selectedKeys={[location.pathname, "/" + location.pathname.split("/")[1]]}
              style={{ height: "100%", borderRight: 0 }}
            >
              <UINavLinkItem
                key={Routes.home}
                to={Routes.home}
                title={"Home"}
                icon={<HomeOutlined />}
              />
              <UINavLinkItem
                key={Routes.notices}
                to={Routes.notices}
                title={"Avvisi"}
                icon={<BellOutlined />}
              />
              <UINavLinkItem
                key={Routes.locations}
                to={Routes.locations}
                title={"Sedi"}
                icon={<ShopOutlined />}
              />
              <UINavLinkItem
                key={Routes.requests}
                to={Routes.requests}
                title={"Richieste"}
                icon={<CheckSquareOutlined />}
              />
              <UINavLinkItem
                key={Routes.users}
                to={Routes.users}
                title={"Dipendenti"}
                icon={<UserOutlined />}
              />
              <UINavLinkItem
                key={Routes.groups}
                to={Routes.groups}
                title={"Gruppi"}
                icon={<UsergroupAddOutlined />}
              />
              <UINavLinkItem
                key={Routes.documents}
                to={Routes.documents}
                title={"Documenti"}
                icon={<FileOutlined />}
              />
              <UINavLinkItem
                key={Routes.settings}
                to={Routes.settings}
                title={"Configurazione"}
                icon={<ToolOutlined />}
              />
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch location={location}>
                <Route
                  path={Routes.home}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewHome} />}
                />
                <Route
                  path={Routes.groups}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewGroupList} />}
                />
                <Route
                  path={Routes.locations}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewLocationList} />}
                />
                <Route
                  path={Routes.documents}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewDocumentList} />}
                />
                <Route
                  path={Routes.settings}
                  render={(): JSX.Element => <RenderRouteLogged component={ViewSettings} />}
                />
                <Route
                  path={Routes.users}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewUserList} />}
                />
                <Route
                  path={Routes.usersNew}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewUserCreate} />}
                />
                <Route
                  path={Routes.groupsNew}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewGroupCreate} />}
                />
                <Route
                  path={Routes.locationsNew}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewLocationCreate} />}
                />
                <Route
                  path={Routes.usersEdit}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewUserEdit} />}
                />
                <Route
                  path={Routes.groupsEdit}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewGroupEdit} />}
                />
                <Route
                  path={Routes.locationsEdit}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewLocationEdit} />}
                />
                <Route
                  path={Routes.notices}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewNoticeList} />}
                />
                <Route
                  path={Routes.noticesNew}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewNoticeCreate} />}
                />
                <Route
                  path={Routes.requests}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewRequestList} />}
                />
                <Route
                  path={Routes.requestTypeNew}
                  exact
                  render={(): JSX.Element => (
                    <RenderRouteLogged component={ViewRequestTypeCreate} />
                  )}
                />
                <Route
                  path={Routes.requestNew}
                  exact
                  render={(): JSX.Element => <RenderRouteLogged component={ViewForgotPassword} />}
                />
                <Route
                  path={Routes.forgotPassword}
                  render={(): JSX.Element => <RenderRouteGuest component={ViewForgotPassword} />}
                />
                <Route
                  path="/"
                  render={(): JSX.Element => <RenderRouteGuest component={ViewLogin} />}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Theme>
  );
};

const CustomLogo = styled(Title)`
  width: 120px;
  height: 31px;
  color: ${(props): string => props.theme.colors.primary}!important;
  margin: 16px 28px 16px 16px;
  float: left;
`;

export default App;
