import { TUser, TUserComplete } from "../../redux-modules/declarations/maps/auth";

export const utilityFormatApiUser = (user: TUser): TUser => ({
    id: user.id,
    name: user.name,
    surname: user.surname,
    email: user.email,
    mobile: user.mobile,
    fiscal_code: user.fiscal_code,
    avatar: user.avatar,
    role: user.role,
    group: user.group,
    office: user.office,
  });
  

export const utilityFormatApiUserList = (list: TUser[]): TUser[] =>
  list.map(user => utilityFormatApiUser(user));


  export const utilityFormatFindList = (user: TUserComplete): TUser => ({
    id: user.id,
    name: user.name,
    surname: user.surname,
    fiscal_code: user.fiscal_code,
    documents: user.documents,
    email: user.email,
    mobile: user.mobile,
    avatar: user.avatar,
    role: user.role.id,
    group: user.group.id,
    office: user.office.id
  })