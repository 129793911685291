/** @format */

import styled from "styled-components";

interface Props {
  color: string;
  width: string;
}

export const IconContainer = styled.div<Props>`
  width: ${(props): string => props.width};
  height: ${(props): string => props.width};
  background-color: ${(props): string => props.color};
  border-radius: ${(props): string => props.width};
  text-align: center;
`;
