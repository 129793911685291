/** @format */

import React from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { useSelector } from "react-redux";
import { selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { Colors } from "../../../theme/maps/Colors";
import { useHistory } from "react-router";
import { useGenericAlert } from "../../../redux-modules/hooks/useGenericAlert";

export const ViewHome = (): JSX.Element => {
  const loggedUser = useSelector(selectorLoggedUser);
  const Alert = useGenericAlert(6000);

  const history = useHistory();
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {Alert}
          <Row gutter={[8, 8]}>
            {loggedUser && (
              <>
                <UITitle level={3} color={Colors.title}>
                  Benvenuto {loggedUser.name} {loggedUser.surname}
                </UITitle>
              </>
            )}
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12} />
            <Col span={12}>{/*
                          <NoticeList />

             */}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
