/** @format */

import { put, call, all } from "redux-saga/effects";
import {
  apiUserAvatar,
  apiUserCreate,
  apiUserDelete,
  apiUserDocumentUpload,
  apiUsersList,
  apiUserUpdate,
} from "../../api/apiUsers";
import { AxiosResponse } from "axios";
import { TAPIResponse } from "../declarations/apis/general";
import {
  actionUsersListLoadingRequest,
  actionUsersListSuccess,
  actionUsersListLoadingSuccess,
  actionUsersListFailure,
  actionUsersListLoadingFailure,
  actionUserCreateFailure,
  actionUserCreateSuccess,
  actionUserCreateReset,
  actionUserDeleteFailure,
  actionUserDeleteSuccess,
  actionUserUpdateFailure,
  actionUserUpdateSuccess,
  actionUserDeleteReset,
  actionUserUpdateReset,
  actionUserAvatarFailure,
  actionUserAvatarReset,
  actionUserAvatarSuccess,
  actionUserDocumentUploadSuccess,
  actionUserDocumentUploadReset,
  actionUserDocumentUploadFailure,
} from "../actions/actionsUsers";
import {
  TActionUserAvatarRequest,
  TActionUserCreateRequest,
  TActionUserDeleteRequest,
  TActionUserDocumentUploadRequest,
  TActionUsersListRequest,
  TActionUserUpdateRequest,
} from "../declarations/actions/users";
import { utilityFormatUserTable } from "../../utils/format/userTable";
import { TUser } from "../declarations/maps/auth";
import { showNotification } from "../../utils/showNotification";

export function* sagaUserCreate(action: TActionUserCreateRequest): unknown {
  try {
    const response = yield call(apiUserCreate, action.payload);

    console.log("response", response);
    yield put(actionUserCreateSuccess(response.data.id));
    showNotification("success", "Creato!", "Utente creato con successo");
    yield put(actionUserCreateReset());
  } catch (e) {
    yield put(actionUserCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaUsersList(action: TActionUsersListRequest): unknown {
  try {
    yield put(actionUsersListLoadingRequest());

    const response: AxiosResponse<TAPIResponse<TUser[]>> = yield call(apiUsersList, action.payload);
    console.log(response.data);
    const usersList = utilityFormatUserTable(response.data);
    console.log(usersList);

    //@ts-ignore
    yield put(actionUsersListSuccess(usersList));
    yield put(actionUsersListLoadingSuccess());
  } catch (e) {
    yield put(actionUsersListFailure());
    yield put(actionUsersListLoadingFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaUserUpdate(action: TActionUserUpdateRequest): unknown {
  try {
    yield call(apiUserUpdate, action.payload);

    yield all([put(actionUserUpdateSuccess()), put(actionUserUpdateReset())]);
    showNotification("success", "Aggiornato!", "Utente modificato con successo");
  } catch (e) {
    yield put(actionUserUpdateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaUserDelete(action: TActionUserDeleteRequest): unknown {
  try {
    yield call(apiUserDelete, action.payload);

    yield all([put(actionUserDeleteSuccess(action.payload)), put(actionUserDeleteReset())]);
    showNotification("success", "Eliminato!", "Utente eliminato con successo");
  } catch (e) {
    yield put(actionUserDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaUserAvatar(action: TActionUserAvatarRequest): unknown {
  try {
    const response = yield call(apiUserAvatar, action.payload);

    console.log(response);
    yield all([put(actionUserAvatarSuccess()), put(actionUserAvatarReset())]);
    showNotification("success", "Aggiornato!", "Nuovo Avatar caricato correttamente");
  } catch (e) {
    yield put(actionUserAvatarFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaUserDocumentUpload(action: TActionUserDocumentUploadRequest): unknown {
  try {
    const response = yield call(apiUserDocumentUpload, action.payload);

    console.log(response);
    yield all([put(actionUserDocumentUploadSuccess()), put(actionUserDocumentUploadReset())]);
    showNotification("success", "Aggiornato!", "Documento caricato con successo");
  } catch (e) {
    yield put(actionUserDocumentUploadFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}
