/** @format */

import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { TablePaginationConfig } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import {
  actionNoticeListRequest,
  actionNoticeListReset,
} from "../../../redux-modules/actions/actionsNotices";
import { selectorNoticeListTable } from "../../../redux-modules/selectors/selectorsNotices";
import { selectorNoticeListStatus } from "../../../redux-modules/selectors/selectorsUI";
import { useRequestStatus } from "../../../redux-modules/hooks/useRequestStatus";
import { noticeListColumns } from "../../../constants/tables/noticeListColumns";
import { utilityFormatNoticeToTableDataSourceList } from "../../../utils/format/noticeTable";
import { TNoticeDatasourceList } from "../../../redux-modules/declarations/maps/notices";
import Tabs from "antd/lib/tabs";
import { NOTICE_FILTERS } from "../../../constants/filters/noticeFilters";
import { useQueryString } from "../../../redux-modules/hooks/useQueryString";
import NoticeExpand from "../../renderers/NoticeExpand";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useHistory } from "react-router";
import { UILink } from "../../ui/Link";

export const ViewNoticeList = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formattedNoticeList, setFormattedNoticeList] = useState<TNoticeDatasourceList>([]);
  const { data, pagination } = useSelector(selectorNoticeListTable);
  const requestStatus = useSelector(selectorNoticeListStatus);
  const { isPending } = useRequestStatus(requestStatus);
  const { status, query } = useQueryString();
  const [currentFilter, setCurrentFilter] = useState<string>(
    status || Object.keys(NOTICE_FILTERS)[0],
  );
  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput(
    query || "",
    () => true,
    false,
    600,
  );

  useEffect(() => {
    dispatch(actionNoticeListReset());
    return (): void => {
      dispatch(actionNoticeListReset());
    };
  }, []);

  useEffect(() => {
    /*history.push({
      ...location,
      search: `?${qs.stringify({ status: currentFilter, query: debouncedQuery || undefined })}`,
    });*/

    dispatch(
      actionNoticeListRequest({
        query: debouncedQuery || undefined,
        status: currentFilter,
      }),
    );
  }, [debouncedQuery, currentFilter]);

  useEffect(() => {
    if (data) setFormattedNoticeList(utilityFormatNoticeToTableDataSourceList(data));
  }, [data]);

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionNoticeListRequest({
        status: currentFilter,
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
      }),
    );
  };

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Avvisi
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.noticesNew}
            label={<UIButton type="primary">+ Crea Avviso</UIButton>}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <UICard>
            <Row justify="space-between" gutter={[16, 0]}>
              <Col />
              <Col>
                <UISearch placeholder="Cerca..." onChange={setQuery} value={currentQuery} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Tabs defaultActiveKey={currentFilter} onChange={setCurrentFilter}>
                  {Object.keys(NOTICE_FILTERS).map(currentKey => (
                    <Tabs.TabPane tab={NOTICE_FILTERS[currentKey]} key={currentKey} />
                  ))}
                </Tabs>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <UITable
                  size="middle"
                  expandable={{ expandedRowRender: NoticeExpand }}
                  loading={isPending}
                  columns={noticeListColumns}
                  dataSource={formattedNoticeList}
                  onChange={handleTableChange}
                  pagination={{ ...pagination }}
                />
              </Col>
            </Row>
          </UICard>
        </Col>
      </Row>
    </UITransition>
  );
};
