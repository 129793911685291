/** @format */

import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { actionGroupCreateRequest } from "../../../redux-modules/actions/actionsGroups";
import { useDispatch, useSelector } from "react-redux";
import { selectorGroupCreated } from "../../../redux-modules/selectors/selectorsUI";
import { UILink } from "../../ui/Link";
const { Option } = Select;

export const ViewGroupCreate = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  /*   const [locationList, setLocationList] = useState<[]>([]);
  const [groupList, setGroupList] = useState<[]>([]); */
  const [userList, setUserList] = useState<[]>([]);
  const isGroupCreated = useSelector(selectorGroupCreated);

  //@ts-ignore
  const handleSave = (e): void => {
    const contentObj = {
      name: e.name,
      description: e.description,
      location: e.location,
      parentGroups: e.parentGroups,
      childrenGroups: e.childrenGroups,
      users: e.users,
      manager_id: e.manager,
      requests: e.requests,
      notifications: e.notifications,
      workableDays: e.workableDays,
    };
    //dispatch
    console.log(contentObj);
    dispatch(actionGroupCreateRequest(contentObj));
  };

  useEffect(() => {
    /* apiClient.get(API_ENDPOINTS.LOCATIONS).then(res => {
      setLocationList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.GROUPS).then(res => {
      setGroupList(res.data.data);
    }); */
    apiClient.get(API_ENDPOINTS.USERS_LIST).then(res => {
      setUserList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (isGroupCreated) history.push(Routes.groups);
  }, [isGroupCreated]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Nuovo Gruppo
          </UITitle>
        </Col>
      </Row>
      <Form name="new-group" className="new-group" onFinish={handleSave} layout="vertical">
        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24}>
            <UICard>
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: "Inserisci un nome!" }]}
                    extra="Il nome del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col>
                {/* <Col xs={12}>
                  <Form.Item
                    name="description"
                    label="Descrizione"
                    rules={[{ required: false, message: "Inserisci un nome!" }]}
                    extra="La descrizione del gruppo"
                  >
                    <UIInput />
                  </Form.Item>
                </Col> */}
              </Row>
              {/*  <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="location"
                    label="Sede"
                    rules={[{ required: false, message: "Seleziona una categoria!" }]}
                    extra="La sede di appartenenza del contenuto"
                  >
                    <UISelect>
                      {locationList.map(location => (
                        //@ts-ignore
                        <Option key={location.id} value={location.id}>
                          {
                            //@ts-ignore
                            location.name
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="workableDays"
                    label="Giorni Lavorativi Settimanali"
                    rules={[{ required: false, message: "Seleziona una quantità!" }]}
                    initialValue={5}
                  >
                    <UIInput type="number" />
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="parentGroups"
                    label="Gruppi Genitori"
                    rules={[{ required: false, message: "Seleziona una categoria!" }]}
                    extra={`Seleziona uno o più gruppi "padre"`}
                  >
                    <UISelect mode="multiple">
                      {groupList.map(group => (
                        //@ts-ignore
                        <Option key={group.id} value={group.id}>
                          {
                            //@ts-ignore
                            group.name
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="childrenGroups"
                    label="Gruppi Figli"
                    rules={[{ required: false, message: "Seleziona una categoria!" }]}
                    extra={`Seleziona uno o più gruppi "figli"`}
                  >
                    <UISelect mode="multiple">
                      {groupList.map(group => (
                        //@ts-ignore
                        <Option key={group.id} value={group.id}>
                          {
                            //@ts-ignore
                            group.name
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row gutter={[8, 8]}>
                {/* <Col xs={24} md={12}>
                  <Form.Item
                    name="users"
                    label="Utenti"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Gli utenti che fanno parte del gruppo"`}
                  >
                    <UISelect mode="multiple">
                      {userList.map(user => (
                        //@ts-ignore
                        <Option key={user.id} value={user.id}>
                          {
                            //@ts-ignore
                            user.name + " " + user.surname
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col> */}
                <Col xs={24} md={12}>
                  <Form.Item
                    name="manager"
                    label="Manager"
                    rules={[{ required: true, message: "Seleziona almeno un utente!" }]}
                    extra={`Il manager del gruppo`}
                  >
                    <UISelect>
                      {userList.map(user => (
                        //@ts-ignore
                        <Option key={user.id} value={user.id}>
                          {
                            //@ts-ignore
                            user.name + " " + user.surname
                          }
                        </Option>
                      ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="requests"
                    label="Richieste Disponibili"
                    rules={[{ required: false, message: "Seleziona una categoria!" }]}
                    extra="Le tipologie di richiesta disponibili per il gruppo"
                  >
                    <UISelect mode="multiple">
                      <Option value="1">Ferie</Option>
                      <Option value="2">Permesso</Option>
                      <Option value="3">Malattia</Option>
                      <Option value="4">Congedo</Option>
                    </UISelect>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="notifications"
                    label="Abilitare notifiche?"
                    rules={[{ required: false, message: "Seleziona una categoria!" }]}
                    extra="Abilitare le notifiche alla creazione e al cambio di stato delle richieste?"
                    initialValue={true}
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                </Col>
              </Row> */}
              <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                <Col>
                  <UILink route={Routes.groups} label={<UIButton>Annulla</UIButton>} />
                </Col>
                <Col>
                  <Form.Item noStyle>
                    <UIButton type="primary" htmlType="submit">
                      + Crea Gruppo
                    </UIButton>
                  </Form.Item>
                </Col>
              </Row>
            </UICard>
          </Col>
        </Row>
      </Form>
    </>
  );
};
