/** @format */

import { Col, Form, Image, Row } from "antd";
import React from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import Logo from "../../../assets/svg/logo.svg";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { MailOutlined } from "@ant-design/icons";
import { UIText } from "../../ui/Text";

export const ViewForgotPassword = (): JSX.Element => {
  const handleRecovery = (forgotObject: {}): void => {
    //@ts-ignore
    const mailObj = { email: forgotObject.email };
    console.log(mailObj);
    //dispatch(actionRecoveryRequest(mailObj));
  };

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <Image width={80} src={Logo} />
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} style={{ fontWeight: "bold" }} color={Colors.title}>
            {process.env.REACT_APP_NAME?.toUpperCase()}
          </UITitle>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col lg={8} sm={18} xs={24}>
          <UICard>
            <Row>
              <Col xs={24}>
                <UITitle level={3} color={Colors.darkGray}>
                  Password dimenticata?
                </UITitle>
              </Col>
            </Row>
            <Row style={{ marginBottom: "25px" }}>
              <Col xs={24}>
                <UIText color={Colors.darkGray}>
                  Inserisci l'indirizzo email utilizzato per accedere; riceverai una mail con le
                  istruzioni per resettare la password.
                </UIText>
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleRecovery}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Inserisci un'email valida!" }]}
              >
                <UIInput placeholder="Email" prefix={<MailOutlined />} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton
                  bgColor={Colors.title}
                  color={Colors.primary}
                  hoverBgColor={Colors.title}
                  borderColor={Colors.title}
                  htmlType="submit"
                  fullWidth
                >
                  RESETTA PASSWORD
                </UIButton>
              </Form.Item>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
