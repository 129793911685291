/** @format */

import React from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UITable } from "../../ui/Table";
import { requestListColumns } from "../../../constants/tables/requestListColumns";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useHistory } from "react-router";
import { UILink } from "../../ui/Link";

const ViewRequestList = (): JSX.Element => {
  const history = useHistory();
  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Richieste
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.requestTypeNew}
            label={<UIButton type="primary">+ Nuova Richiesta</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col />
          <Col>
            <UISearch placeholder="Cerca..." />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              //expandable={{ expandedRowRender: NoticeExpand }}
              //loading={isPending}
              size="middle"
              columns={requestListColumns}
              dataSource={[{ title: "Test", status: "Accettata", actions: "1" }]}
              //onChange={handleTableChange}
              //pagination={{ ...pagination }}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};

export default ViewRequestList;
