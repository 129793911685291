/** @format */

import React, { useEffect, useState } from "react";
import { DocumentsTabEdit } from "./partials/DocumentsTabEdit";
import { PersonalInfoTabEdit } from "./partials/PersonalInfoTabEdit";
import { ProfessionalInfoTabEdit } from "./partials/ProfessionalInfoTabEdit";
import { Skeleton, Col, Form, Row, Tabs, Breadcrumb } from "antd";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { utilityFormatFindList } from "../../../utils/format/apiUser";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUserDeleteRequest,
  actionUserDocumentUploadRequest,
  actionUserUpdateRequest,
} from "../../../redux-modules/actions/actionsUsers";
import {
  selectorUserAvatarUpdated,
  selectorUserDeleted,
  selectorUserDocumentUpdated,
  selectorUserUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { TUserComplete } from "../../../redux-modules/declarations/maps/auth";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { NewDocumentDrawer } from "./partials/NewDocumentDrawer";
import { TUserDocumentUploadRequest } from "../../../redux-modules/declarations/maps/users";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionUserDeleteRequest } from "../../../redux-modules/declarations/actions/users";
import { UILink } from "../../ui/Link";

const { TabPane } = Tabs;

export const ViewUserEdit = (): JSX.Element => {
  const callback = (key: string): void => {
    console.log("active tab", key);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserUpdated = useSelector(selectorUserUpdated);
  const isUserDeleted = useSelector(selectorUserDeleted);
  const isUserAvatarUpdated = useSelector(selectorUserAvatarUpdated);
  const isDocumentUploaded = useSelector(selectorUserDocumentUpdated);

  //@ts-ignore
  const { id_user } = useParams();

  const [userInfo, setUserInfo] = useState<TUserComplete>();
  const [roleList, setRoleList] = useState<[]>([]);
  const [groupList, setGroupList] = useState<[]>([]);
  const [locationList, setLocationList] = useState<[]>([]);
  const [newDocumentVisible, setNewDocumentVisible] = useState(false);

  const removeUser = (): TActionUserDeleteRequest => dispatch(actionUserDeleteRequest(id_user));

  const showNewDocumentDrawer = (): void => setNewDocumentVisible(true);
  const onNewDocumentClose = (): void => setNewDocumentVisible(false);
  const handleNewDocumentSave = (info: TUserDocumentUploadRequest): void => {
    console.log(info);
    //dispatch save
    dispatch(actionUserDocumentUploadRequest(info));
  };

  //@ts-ignore
  const handleUpdate = (e): void => {
    const userObj = {
      id: id_user,
      fiscal_code: e.fiscal_code,
      name: e.name,
      surname: e.surname,
      email: e.email,
      phone: e.phone,
      phone2: e.phone2,
      mobile: e.mobile,
      mobile2: e.mobile2,
      password: e.password,
      resetPasswordTime: e.resetPasswordTime,
      address: e.address,
      city: e.city,
      cap: e.cap,
      state: e.state,
      office_id: e.office || userInfo?.office,
      group_id: e.group || userInfo?.group,
      role_id: e.role,
      pinCode: e.pinCode,
      qrCode: e.qrCode,
      workEmail: e.workEmail,
      intNumberPhone: e.intNumberPhone,
      relationship: e.relationship,
      /* avatar: avatar, */
    };
    //dispatch
    dispatch(actionUserUpdateRequest(userObj));
  };

  useEffect(() => {
    if (isUserUpdated || isUserDeleted) history.push(Routes.users);
  }, [isUserUpdated, isUserDeleted]);

  useEffect(() => {
    if (isDocumentUploaded) onNewDocumentClose();
  }, [isDocumentUploaded]);

  //@ts-ignore
  useEffect(() => {
    setUserInfo(undefined);
    apiClient.get(API_ENDPOINTS.ROLES).then(res => {
      setRoleList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.GROUPS).then(res => {
      setGroupList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.LOCATIONS).then(res => {
      setLocationList(res.data.data);
    });

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.USERS_LIST + "/" + id_user);
      //@ts-ignore
      setUserInfo(utilityFormatFindList(response.data.data));
      fetch(response.data.data.avatar, { mode: "no-cors" }).then(res => console.log(res));
      //apiClient.get(response.data.data.avatar).then(res => console.log(res));
    };
    fetchData();
  }, [isUserAvatarUpdated, isDocumentUploaded]);

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={Colors.title}>
                Dipendenti
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.users} label={"Dipendenti"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {userInfo ? userInfo.name + " " + userInfo.surname : ""}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col xs={24}>
          {!!!userInfo ? (
            <UICard style={{ marginBottom: "20px" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <Form
              name="update-user"
              className="update-user"
              onFinish={handleUpdate}
              initialValues={userInfo}
              layout="vertical"
            >
              <UICard style={{ marginBottom: "20px" }}>
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane tab="Informazioni Personali" key="1">
                    <PersonalInfoTabEdit roles={roleList} avatar={userInfo?.avatar} />
                  </TabPane>
                  <TabPane tab="Informazioni Professionali" key="2">
                    <ProfessionalInfoTabEdit groups={groupList} locations={locationList} />
                  </TabPane>
                  <TabPane tab="Documenti" key="3">
                    <DocumentsTabEdit
                      onAddDocument={showNewDocumentDrawer}
                      documents={userInfo?.documents}
                    />
                  </TabPane>
                </Tabs>
                <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                  <Col>
                    <UILink route={Routes.users} label={<UIButton>Annulla</UIButton>} />
                  </Col>
                  <Col>
                    <Form.Item noStyle>
                      <UIButton type="primary" htmlType="submit">
                        Salva Modifiche
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </UICard>
              <NewDocumentDrawer
                onClose={onNewDocumentClose}
                visible={newDocumentVisible}
                onSave={handleNewDocumentSave}
              />
            </Form>
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: "30px" }}>
        <Col xs={24}>
          <DeleteCard
            actionToDispatch={removeUser}
            title="Elimina Dipendente"
            text="Cliccando sul bottone elimina eliminerai definitivamente il dipendente selezionato. Assicurati di voler rimuovere l'utente."
            removeButtonLabel="Elimina Dipendente"
          />
        </Col>
      </Row>
    </>
  );
};

/*
import React, { useEffect, useState } from "react";
import { DocumentsTabEdit } from "./partials/DocumentsTabEdit";
import { PersonalInfoTabEdit } from "./partials/PersonalInfoTabEdit";
import { ProfessionalInfoTabEdit } from "./partials/ProfessionalInfoTabEdit";
import { Skeleton, Col, Form, Row, Tabs, Breadcrumb } from "antd";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { utilityFormatFindList } from "../../../utils/format/apiUser";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUserDocumentUploadRequest,
  actionUserUpdateRequest,
} from "../../../redux-modules/actions/actionsUsers";
import {
  selectorUserAvatarUpdated,
  selectorUserDocumentUpdated,
  selectorUserUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { TUserComplete } from "../../../redux-modules/declarations/maps/auth";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { NewDocumentDrawer } from "./partials/NewDocumentDrawer";
import { TUserDocumentUploadRequest } from "../../../redux-modules/declarations/maps/users";
import { UILink } from "../../ui/Link";

const { TabPane } = Tabs;

export const ViewUserEdit = (): JSX.Element => {
  const callback = (key: string): void => {
    console.log("active tab", key);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserUpdated = useSelector(selectorUserUpdated);
  const isUserAvatarUpdated = useSelector(selectorUserAvatarUpdated);
  const isDocumentUploaded = useSelector(selectorUserDocumentUpdated);

  //@ts-ignore
  const { id_user } = useParams<{ id_user: string }>();

  const [userInfo, setUserInfo] = useState<TUserComplete>();
  const [roleList, setRoleList] = useState<[]>([]);
  const [groupList, setGroupList] = useState<[]>([]);
  const [locationList, setLocationList] = useState<[]>([]);
  const [newDocumentVisible, setNewDocumentVisible] = useState(false);

  const showNewDocumentDrawer = (): void => setNewDocumentVisible(true);
  const onNewDocumentClose = (): void => setNewDocumentVisible(false);
  const handleNewDocumentSave = (info: TUserDocumentUploadRequest): void => {
    console.log(info);
    //dispatch save
    dispatch(actionUserDocumentUploadRequest(info));
  };

  //@ts-ignore
  const handleUpdate = (e): void => {
    const userObj = {
      id: id_user,
      fiscal_code: e.fiscal_code,
      name: e.name,
      surname: e.surname,
      email: e.email,
      phone: e.phone,
      phone2: e.phone2,
      mobile: e.mobile,
      mobile2: e.mobile2,
      password: e.password,
      resetPasswordTime: e.resetPasswordTime,
      address: e.address,
      city: e.city,
      cap: e.cap,
      state: e.state,
      office_id: e.office || userInfo?.office,
      group_id: e.group || userInfo?.group,
      role_id: e.role,
      pinCode: e.pinCode,
      qrCode: e.qrCode,
      workEmail: e.workEmail,
      intNumberPhone: e.intNumberPhone,
      relationship: e.relationship,
      /* avatar: avatar,
    };
    //dispatch
    dispatch(actionUserUpdateRequest(userObj));
  };

  useEffect(() => {
    if (isUserUpdated) history.push(Routes.users);
  }, [isUserUpdated]);

  useEffect(() => {
    if (isDocumentUploaded) onNewDocumentClose();
  }, [isDocumentUploaded]);

  //@ts-ignore
  useEffect(() => {
    setUserInfo(undefined);
    apiClient.get(API_ENDPOINTS.ROLES).then(res => {
      setRoleList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.GROUPS).then(res => {
      setGroupList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.LOCATIONS).then(res => {
      setLocationList(res.data.data);
    });

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchData = async () => {
      const response = await apiClient.get(API_ENDPOINTS.USERS_LIST + "/" + id_user);
      //@ts-ignore
      setUserInfo(utilityFormatFindList(response.data.data));
      fetch(response.data.data.avatar, { mode: "no-cors" }).then(res => console.log(res));
      //apiClient.get(response.data.data.avatar).then(res => console.log(res));
    };
    fetchData();
  }, [isUserAvatarUpdated, isDocumentUploaded]);

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={Colors.title}>
                Dipendenti
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.users} label={"Dipendenti"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {userInfo ? userInfo.name + " " + userInfo.surname : ""}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col xs={24}>
          {!!!userInfo ? (
            <UICard style={{ marginBottom: "20px" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <Form
              name="update-user"
              className="update-user"
              onFinish={handleUpdate}
              initialValues={userInfo}
              layout="vertical"
            >
              <UICard style={{ marginBottom: "20px" }}>
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane tab="Informazioni Personali" key="1">
                    <PersonalInfoTabEdit roles={roleList} avatar={userInfo?.avatar} />
                  </TabPane>
                  <TabPane tab="Informazioni Professionali" key="2">
                    <ProfessionalInfoTabEdit groups={groupList} locations={locationList} />
                  </TabPane>
                  <TabPane tab="Documenti" key="3">
                    <DocumentsTabEdit
                      onAddDocument={showNewDocumentDrawer}
                      documents={userInfo?.documents}
                    />
                  </TabPane>
                </Tabs>
                <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                  <Col>
                    <UILink route={Routes.users} label={<UIButton>Annulla</UIButton>} />
                  </Col>
                  <Col>
                    <Form.Item noStyle>
                      <UIButton type="primary" htmlType="submit">
                        Salva Modifiche
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </UICard>
              <NewDocumentDrawer
                onClose={onNewDocumentClose}
                visible={newDocumentVisible}
                onSave={handleNewDocumentSave}
              />
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};
*/
