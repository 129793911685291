/** @format */
import { TList } from "../../redux-modules/declarations/maps/general";

export const utilityFormatQueryStringIteratorToObject = (
  initialValue: TList<string>,
  iterator: IterableIterator<[string, string]>,
): TList<string> => {
  const item = iterator.next();
  if (item.done) return initialValue;
  return {
    ...utilityFormatQueryStringIteratorToObject(initialValue, iterator),
    [item.value[0]]: item.value[1],
  };
};
