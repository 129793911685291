/** @format */

import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  src: string;
  imgWidth?: string;
  imgHeight?: string;
  alt?: string;
  round?: boolean;
  css?: string;
  objectFit?: string;
  borderColor?: string;
}

const StyledImage = styled.img<Props>`
  object-fit: ${(props): string => (props.objectFit ? `${props.objectFit}` : `cover`)};
  position: relative;

  ${(props): string => (props.round ? "border-radius: 50%;" : "")}
  ${(props): string => (props.borderColor ? "border: 1px solid " + props.borderColor + ";" : "")}
  ${(props): string => props.css || ""}
    width: ${(props): string => props.imgWidth || "100%"};
  height: ${(props): string => props.imgHeight || ""};
`;

export const UIImage: FC<Props> = ({
  objectFit,
  src,
  imgWidth,
  imgHeight,
  alt,
  round,
  css,
  borderColor,
}): JSX.Element => (
  <StyledImage
    objectFit={objectFit}
    round={round}
    src={src}
    imgWidth={imgWidth}
    imgHeight={imgHeight}
    alt={alt}
    borderColor={borderColor}
    css={css}
  />
);
