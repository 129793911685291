/** @format */

import { TNotices } from "../declarations/maps/notices";
import { TActionNoticeListSuccess } from "../declarations/actions/notices";
import { TActionType } from "../declarations/actionTypes";
import { DEFAULT_TABLE_PAGINATION_STATE } from "../../constants/defaultPagination";

export type TActions = TActionNoticeListSuccess;

export const reducerNotices = (
  state: TNotices = {
    list: {
      data: [
        {
          id: "1",
          title: "Titolo",
          content: "Contenut",
          read: false,
        },
      ],
      pagination: DEFAULT_TABLE_PAGINATION_STATE,
    },
  },
  action: TActions,
): TNotices => {
  switch (action.type) {
    case TActionType.noticeListSuccess:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};
