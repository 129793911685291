/** @format */
//@ts-nocheck
import { notification } from "antd";

const notificationStyle = {
  borderRadius: "7px",
  whiteSpace: "break-spaces",
};

export const showNotification = (
  type: "error" | "warning" | "success" | "notification",
  message: string,
  description: string,
): void => {
  const options = { message, description, style: notificationStyle, duration: 3 };
  switch (type) {
    case "error":
      notification.error({ ...options });
      break;
    case "warning":
      notification.warn({ ...options });
      break;
    case "success":
      notification.success({ ...options });
      break;
    case "notification":
      notification.open({ ...options });
      break;
    default:
      notification.open({ ...options });
      break;
  }
};
