/** @format */

import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";

import { documentListColumns } from "../../../constants/tables/documentListColumns";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useHistory } from "react-router";
import { UILink } from "../../ui/Link";
import { useDispatch } from "react-redux";
import { actionDocumentListRequest } from "../../../redux-modules/actions/actionsDocument";

export const ViewDocumentList = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  /*   const dispatch = useDispatch();
  const documentList = useSelector(selectorDocumentList);
  const documentListLoading = useSelector(selectorDocumentListLoading);
  const isDocumentDeleted = useSelector(selectorDocumentDeleted);

  useEffect(() => {
    if (isDocumentDeleted)
      dispatch(
        actionDocumentListRequest({
          query: debouncedQuery || undefined,
        }),
      );
  }, [isDocumentDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedDocumentList = utilityFormatDocumentsToTableDataSourceList(documentList.data);

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionDocumentListRequest({
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      actionDocumentListRequest({
        query: debouncedQuery || undefined,
      }),
    );
  }, [debouncedQuery]); */

  useEffect(() => {
    dispatch(actionDocumentListRequest());
  }, []);

  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Documenti
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.documentsNew}
            label={<UIButton type="primary">+ Carica Documento</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col />
          <Col>
            <UISearch placeholder="Cerca..." /*  onChange={setQuery} value={currentQuery} */ />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              /*             loading={documentListLoading}
               */ columns={documentListColumns}
              /* dataSource={formattedDocumentList}
            onChange={handleTableChange}
            pagination={{ ...documentList.pagination }} */
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
