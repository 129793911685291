/** @format */

import { put, call, select } from "redux-saga/effects";
import { TActionLoginRequest } from "../declarations/actions/auth";
import {
  actionLoginSuccess,
  actionLoginFailure,
  actionLogoutFailure,
  actionLogoutSuccess,
  actionLogoutRequest,
  actionFailureApiKeyCheck,
  actionEndApiKeyCheck,
  actionStartApiKeyCheck,
} from "../actions/actionsAuth";
import { apiLogout } from "../../api/auth/apiLogout";
import { apiLogin } from "../../api/auth/apiLogin";
import { AxiosError, AxiosResponse } from "axios";
import { utilitySaveToLocalStorage } from "../../utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "../../utils/cache/getFromLocalStorage";
import { selectorLoggedUser } from "../selectors/selectorsAuth";
import { sleep } from "../../utils/sleep";
import { TAPIValidationError } from "../declarations/apis/general";

export function* sagaLogin(action: TActionLoginRequest): unknown {
  console.log("fatto");

  const {
    response: tokenResponse,
    error,
  }: { response?: AxiosResponse; error?: AxiosError<{ errors: TAPIValidationError }> } = yield call(
    apiLogin,
    {
      email: action.payload.email,
      password: action.payload.password,
    },
  );
  if (!error && tokenResponse) {
    const token = tokenResponse.data.token;
    const userData = tokenResponse.data.user;
    utilitySaveToLocalStorage("apiKey", token);

    yield put(actionLoginSuccess(userData));
    yield put(actionStartApiKeyCheck());
  } else {
    yield put(actionLoginFailure((error?.response?.data?.errors as TAPIValidationError) || {}));
  }
}

export function* sagaLogout(): unknown {
  try {
    try {
      yield call(apiLogout);
    } catch (e) {
      //@ts-ignore
      console.error(e.stack);
    }
    yield put(actionLogoutSuccess());
  } catch {
    yield put(actionLogoutFailure());
  }
}

export function* sagaCheckApiToken(): unknown {
  while (true) {
    try {
      const userUid = yield select(selectorLoggedUser);
      if (!userUid) return;
      const apiKey = utilityGetFromLocalStorage("apiKey");
      if (!apiKey) {
        yield put(actionLogoutRequest());
        yield put(actionEndApiKeyCheck());
        return;
      }
      //yield put(actionSuccessApiKeyCheck());
    } catch (e) {
      //@ts-ignore
      yield put(actionFailureApiKeyCheck(e.stack));
    }
    yield sleep(6000);
  }
}
