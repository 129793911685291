/** @format */

import { TNoticeCreateRequest } from "../../redux-modules/declarations/maps/notices";
import { AxiosResponse } from "axios";
import { utilityGenerateFormDataFromObject } from "../../utils/generateFormDataFromObject";
import apiClient from "../../utils/apiClient";
import { API_ENDPOINTS } from "../../constants/api";

export const apiNoticeCreate = (data: TNoticeCreateRequest): Promise<AxiosResponse> => {
  const formData = utilityGenerateFormDataFromObject(data);
  return apiClient.post(API_ENDPOINTS.NOTICES, formData);
};
