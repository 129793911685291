/** @format */

import {
  TActionAlertGenerate,
  TActionAlertRemove,
  TActionNavigationFailure,
  TActionNavigationRequest,
  TActionNavigationSuccess,
  TActionStaticAlertGenerate,
  TActionStaticAlertRemove,
} from "../declarations/actions/ui";
/** @format */

import { TActionType } from "../declarations/actionTypes";
import { TAlert } from "../declarations/maps/ui";

export const actionNavigationRequest = (payload: number): TActionNavigationRequest => ({
  type: TActionType.navigationRequest,
  payload,
});

export const actionNavigationSuccess = (payload: unknown): TActionNavigationSuccess => ({
  type: TActionType.navigationSuccess,
  payload,
});

export const actionNavigationFailure = (): TActionNavigationFailure => ({
  type: TActionType.navigationFailure,
});

// Alerts
export const actionAlertGenerate = (payload: TAlert): TActionAlertGenerate => ({
  type: TActionType.alertGenerate,
  payload,
});

export const actionAlertRemove = (): TActionAlertRemove => ({
  type: TActionType.alertRemove,
});

export const actionStaticAlertGenerate = (payload: TAlert): TActionStaticAlertGenerate => ({
  type: TActionType.staticAlertGenerate,
  payload,
});

export const actionStaticAlertRemove = (): TActionStaticAlertRemove => ({
  type: TActionType.staticAlertRemove,
});
