/** @format */

import { Col, Row } from "antd";
import React from "react";
import { Routes } from "../../utils/Routes";
import { UILink } from "../../components/ui/Link";

export const userListColumns = [
  {
    title: "",
    dataIndex: "avatar",
    key: "avatar",
    showMobile: true,
  },
  {
    title: "Utente",
    dataIndex: "user",
    key: "user",
    showMobile: true,
  },
  {
    title: "Ruolo",
    dataIndex: "role",
    key: "role",
    showMobile: false,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    showMobile: false,
  },
  {
    title: "Telefono",
    dataIndex: "mobile",
    key: "mobile",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];

//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => (
  <Row gutter={[12, 0]}>
    <Col>
      <UILink route={Routes.usersEdit.replace(":id_user", row.id)} label={"Gestisci"} />
    </Col>
  </Row>
);
