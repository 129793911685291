/** @format */

import React, { FC } from "react";
import { IconContainer } from "./partials/IconContainer";

interface Props {
  width: string;
  color: string;
  children?: JSX.Element;
}

const UIRoundIcon: FC<Props> = ({ width, color, children }): JSX.Element => (
  <IconContainer width={width} color={color}>
    <div
      style={{
        display: "flex",
        height: "100%",
      }}
    >
      <div style={{ display: "inline-flex", alignItems: "center", margin: "auto" }}>{children}</div>
    </div>
  </IconContainer>
);

export default UIRoundIcon;
