/** @format */

import { Col, Drawer, Form, Row, Select } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { actionDocumentTypeListRequest } from "../../../../redux-modules/actions/actionsDocumentType";
import { TUserDocumentUploadRequest } from "../../../../redux-modules/declarations/maps/users";
import { selectorDocumentTypesList } from "../../../../redux-modules/selectors/selectorsDocumentTypes";
import { UIButton } from "../../../ui/Button";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UIFileUploader } from "../../../ui/FileUploader";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (files: TUserDocumentUploadRequest) => void;
}

export const NewDocumentDrawer: FC<Props> = ({ visible, onClose, onSave }: Props): JSX.Element => {
  const [form] = Form.useForm();
  const [newFile, setNewFile] = useState<File | null>();
  const [documentTypes, setDocumentTypes] = useState([]);
  //@ts-ignore
  const { id_user } = useParams();
  const dispatch = useDispatch();
  const documentTypeList = useSelector(selectorDocumentTypesList);

  const handleUpload = (e: FileList | object[]): void => {
    if (e[0]) {
      //@ts-ignore
      setNewFile(e[0]?.originFileObj);
    }
  };

  const handleSave = (): void => {
    form.validateFields();
    let fileObj = form.getFieldsValue();
    fileObj.file = newFile;
    fileObj.user_id = id_user;
    fileObj.valid_at = moment(form.getFieldValue("valid_at")).format("YYYY-MM-DD");

    onSave(fileObj);
  };

  useEffect(() => {
    dispatch(actionDocumentTypeListRequest());
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (documentTypeList) setDocumentTypes(documentTypeList);
  }, [documentTypeList]);

  return (
    <Drawer
      title="Nuovo Documento"
      placement="right"
      onClose={onClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <UIButton onClick={onClose} style={{ marginRight: 8 }}>
            Annulla
          </UIButton>
          <UIButton onClick={handleSave} type="primary">
            Carica
          </UIButton>
        </div>
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="document_type_id"
              label="Tipologia"
              rules={[{ required: true, message: "Inserisci una tipologia di documento" }]}
            >
              <UISelect>
                {documentTypes.map(dtype => (
                  <Option
                    //@ts-ignore
                    value={dtype.id}
                    //@ts-ignore
                    key={dtype.id}
                  >
                    {
                      //@ts-ignore
                      dtype.name
                    }
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="valid_at"
              label="Scadenza"
              rules={[{ required: false, message: "Seleziona la data di scadenza del documento" }]}
            >
              <UIDatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <UIFileUploader handleUpload={handleUpload} />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
