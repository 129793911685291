/** @format */

import { watcherAuth } from "./watcherAuth";
import { watcherCache } from "./watcherCache";
import { watcherDocumentTypes } from "./watcherDocumentTypes";
import { watcherGroups } from "./watcherGroups";
import { watcherLocations } from "./watcherLocations";
import { watcherUsers } from "./watcherUsers";
import { watcherNotices } from "./watcherNotices";
import { watcherDocuments } from "./watcherDocuments";
/** @format */

export const watchers = [
  watcherAuth,
  watcherCache,
  watcherUsers,
  watcherGroups,
  watcherLocations,
  watcherNotices,
  watcherDocumentTypes,
  watcherDocuments,
];
