/** @format */

import { takeLatest } from "redux-saga/effects";
import { TActionType } from "../declarations/actionTypes";
import { sagaNoticeCreate, sagaNoticeList } from "../sagas/sagasNotices";

export function* watcherNotices(): unknown {
  yield takeLatest(TActionType.noticeListRequest, sagaNoticeList);
  yield takeLatest(TActionType.noticeCreateRequest, sagaNoticeCreate);
}
