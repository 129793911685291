/** @format */

import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../constants/api";
import { TDocumentCreateRequest } from "../redux-modules/declarations/maps/documents";
import apiClient from "../utils/apiClient";

export const apiDocumentList = (): Promise<AxiosResponse<unknown>> => {
  return apiClient.get(API_ENDPOINTS.DOCUMENTS);
};

export const apiDocumentCreate = (
  request: TDocumentCreateRequest,
): Promise<AxiosResponse<unknown>> => apiClient.post(API_ENDPOINTS.DOCUMENTS, request);

export const apiDocumentDelete = (userId: string): Promise<AxiosResponse<unknown>> =>
  apiClient.delete(API_ENDPOINTS.DOCUMENTS + "/" + userId);
