/** @format */

import { Row, Col } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { actionDocumentTypeDeleteRequest } from "../../redux-modules/actions/actionsDocumentType";
import { TActionDocumentTypeDeleteRequest } from "../../redux-modules/declarations/actions/documentType";
import { Routes } from "../../utils/Routes";
import { useHistory } from "react-router";
import { UILink } from "../../components/ui/Link";

export const documentTypeColumns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    showMobile: true,
  },
  {
    title: "Splittabile",
    dataIndex: "split",
    key: "split",
    showMobile: false,
  },
  {
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    showMobile: true,
    //@ts-ignore
    // eslint-disable-next-line react/display-name
    render: (text, row, index): JSX.Element => (
      <ActionDropdown text={text} row={row} index={index} />
    ),
  },
];
//@ts-ignore
const ActionDropdown = ({ text, row, index }): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const removeItem = (): TActionDocumentTypeDeleteRequest =>
    dispatch(actionDocumentTypeDeleteRequest(row.id));
  return (
    <Row gutter={[12, 0]}>
      <Col>
        <UILink route={Routes.settings + "/tipologia-documento/" + row.id} label={"Modifica"} />
      </Col>
      |
      <Col onClick={removeItem}>
        <a>Elimina</a>
      </Col>
    </Row>
  );
};
