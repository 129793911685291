/** @format */

import { Row, Col, Select, Form, Skeleton } from "antd";
import React, { FC } from "react";
import { UISelect } from "../../../ui/Select";
const { Option } = Select;

interface Props {
  groups: [];
  locations: [];
}

export const ProfessionalInfoTabEdit: FC<Props> = ({ groups, locations }: Props): JSX.Element => {
  console.log("groups", groups);
  return (
    <>
      <Row gutter={[12, 0]}>
        <Col md={12}>
          <Form.Item name="office" label="Sede predefinita" extra="seleziona la sede dell'utente">
            <UISelect>
              {locations.map(location => (
                //@ts-ignore
                <Option key={location.id} value={location.id}>
                  {
                    //@ts-ignore
                    location.name
                  }
                </Option>
              ))}
            </UISelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 10]}>
        <Col md={12}>
          {!groups.length && (
            <>
              <Skeleton.Input active={true} size={"default"} style={{ width: "200px" }} />
              <br />
              <br />
              <Skeleton.Input active={true} size={"default"} style={{ width: "280px" }} />
            </>
          )}
          {!!groups.length && (
            <Form.Item
              name="group"
              label="Gruppo"
              extra="seleziona il gruppo dell'utente"
              rules={[{ required: true, message: "Inserisci il gruppo  dell'utente!" }]}
            >
              <UISelect>
                {groups.map(group => (
                  //@ts-ignore
                  <Option key={group.id} value={group.id}>
                    {
                      //@ts-ignore
                      group.name
                    }
                  </Option>
                ))}
              </UISelect>
            </Form.Item>
          )}
        </Col>
      </Row>
    </>
  );
};
