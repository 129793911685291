/** @format */

import { AxiosResponse } from "axios";
import { put, all, call } from "redux-saga/effects";
import { apiDocumentCreate, apiDocumentDelete, apiDocumentList } from "../../api/apiDocuments";
import { showNotification } from "../../utils/showNotification";
import {
  actionDocumentCreateSuccess,
  actionDocumentCreateReset,
  actionDocumentCreateFailure,
  actionDocumentListFailure,
  actionDocumentDeleteSuccess,
  actionDocumentDeleteReset,
  actionDocumentListSuccess,
} from "../actions/actionsDocument";
import { actionGroupDeleteFailure } from "../actions/actionsGroups";
import {
  TActionDocumentCreateRequest,
  TActionDocumentListRequest,
  TActionDocumentDeleteRequest,
} from "../declarations/actions/document";
import { TAPIResponse } from "../declarations/apis/general";
/* import { TDocument } from "../declarations/maps/documents";
 */
export function* sagaDocumentCreate(action: TActionDocumentCreateRequest): unknown {
  try {
    const response = yield call(apiDocumentCreate, action.payload);

    yield put(actionDocumentCreateSuccess(response.data.id));
    yield put(actionDocumentCreateReset());
    showNotification("success", "Caricato!", "Documento caricato con successo");
  } catch (e) {
    yield put(actionDocumentCreateFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaDocumentList(action: TActionDocumentListRequest): unknown {
  try {
    const response: AxiosResponse<TAPIResponse<unknown>> = yield call(apiDocumentList);

    //@ts-ignore
    yield put(actionDocumentListSuccess(response.data.data));
  } catch (e) {
    yield put(actionDocumentListFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}

export function* sagaDocumentDelete(action: TActionDocumentDeleteRequest): unknown {
  try {
    yield call(apiDocumentDelete, action.payload);

    yield all([put(actionDocumentDeleteSuccess(action.payload)), put(actionDocumentDeleteReset())]);
    showNotification("success", "Eliminato!", "Documento eliminato con successo");
  } catch (e) {
    yield put(actionGroupDeleteFailure());
    showNotification(
      "error",
      "Oops..",
      //@ts-ignore
      Object.values(e.response.data.errors).join(`\n`),
    );
  }
}
