/** @format */

import React, { FC } from "react";
import { Tabs } from "antd";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import { TabsProps } from "antd/lib/tabs";
import { RenderRouteLogged } from "../../utils/RouteLogged";
const { TabPane } = Tabs;

/**
 * Example of routeConfig: 
 * {
        menu: {
            label: "Menu",
            component: Menu,
            getRoute: url => `${url}/menu`
        },
        "menu-holiday-slot": {
            label: "Menu Holiday Slot",
            component: MenuHolidaySlots,
            getRoute: url => `${url}/menu-holiday-slot`
        },
        overview: {
            label: "Overview",
            component: RestaurantOverview,
            getRoute: url => url
        },
    }
    This will render three tabs Overview, Menu, Menu Holiday Slot and routes based on what getRoute method returns.
    !! NB !! :
    The "/" tab (equal to the basePath) always need to be at the end.
 */

export interface RouteConfigProps {
  label: string;
  component: FC;
  getRoute: (url: string) => string; //es (url) => `${url}/menu-holiday-slot`
}
export interface RouteConfigObject {
  [name: string]: RouteConfigProps;
}

interface Props {
  tabsProps: TabsProps; // https://ant.design/components/tabs/#Tabs
  routeConfig: RouteConfigObject;
  basePath?: string;
}

const RoutedTabs: FC<Props> = ({ tabsProps, routeConfig, basePath }: Props) => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  let tabToRouteMap: { [name: string]: {} } = {};
  let routeToTabsMap: { [name: string]: string } = {};

  for (const routeKey in routeConfig) {
    if (Object.prototype.hasOwnProperty.call(routeConfig, routeKey)) {
      const configObj = routeConfig[routeKey];
      const routeUrl = configObj.getRoute(url);
      tabToRouteMap[routeKey] = routeUrl;
      routeToTabsMap[routeUrl] = routeKey;
    }
  }

  const defaultActiveKey = routeToTabsMap[history.location.pathname];

  let routeNodes = [];
  for (const routeKey in routeConfig) {
    if (Object.prototype.hasOwnProperty.call(routeConfig, routeKey)) {
      const configObj = routeConfig[routeKey];
      routeNodes.push(
        <Route
          path={configObj.getRoute(path)}
          key={basePath ? basePath + "/" + routeKey : routeKey}
          render={(): JSX.Element => <RenderRouteLogged component={configObj.component} />}
        />,
      );
    }
  }
  let tabPaneNodes = [];
  for (const routeKey in routeConfig) {
    if (Object.prototype.hasOwnProperty.call(routeConfig, routeKey)) {
      const configObj = routeConfig[routeKey];
      tabPaneNodes.push(
        <TabPane tab={configObj.label} key={routeKey}>
          <Switch>{routeNodes}</Switch>
        </TabPane>,
      );
    }
  }

  const onTabChange = (activeKey: string): void => {
    history.push(tabToRouteMap[activeKey]);
  };
  return (
    <>
      <Tabs {...tabsProps} onChange={onTabChange} defaultActiveKey={defaultActiveKey}>
        {tabPaneNodes.reverse()}
      </Tabs>
    </>
  );
};

export default RoutedTabs;
