/** @format */

import { Checkbox, Col, Form, Image, Row } from "antd";
import React, { useEffect } from "react";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIInput } from "../../ui/Input";
import { UIInputPassword } from "../../ui/Input/InputPassword";
import Logo from "../../../assets/svg/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { actionLoginRequest, actionLoginReset } from "../../../redux-modules/actions/actionsAuth";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import { Routes } from "../../../utils/Routes";
import { selectorLoginValidation } from "../../../redux-modules/selectors/selectorsUI";
import { useAlert } from "../../../redux-modules/hooks/useAlert";
import { UIAlert } from "../../ui/Alert";
import { useHistory } from "react-router";

export const ViewLogin = (): JSX.Element => {
  const history = useHistory();
  const validation = useSelector(selectorLoginValidation);
  const [visible, setVisible] = useAlert(6000);
  const dispatch = useDispatch();
  const handleLogin = (loginObject: {}): void => {
    console.log(loginObject);
    //@ts-ignore
    const authObj = { email: loginObject.email, password: loginObject.password };
    dispatch(actionLoginRequest(authObj));
  };

  useEffect(() => {
    return (): void => {
      dispatch(actionLoginReset());
    };
  }, []);

  useEffect(() => {
    if (!!Object.values(validation).length) setVisible(true);
  }, [validation]);

  useEffect(() => {
    if (!visible && !!Object.values(validation).length) dispatch(actionLoginReset());
  }, [visible]);

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <Image width={80} src={Logo} />
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} style={{ fontWeight: "bold" }} color={Colors.title}>
            {process.env.REACT_APP_NAME?.toUpperCase()}
          </UITitle>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col lg={8} sm={18} xs={24}>
          <UICard>
            <Row>
              <Col xs={12}>
                <UITitle level={2} color={Colors.darkGray}>
                  Login
                </UITitle>
              </Col>
            </Row>
            {visible &&
              Object.values(validation)
                .reduce((list, current) => [...list, ...current], [])
                .map(current => (
                  <Row key={current} justify="center" gutter={[16, 16]}>
                    <Col span={24}>
                      <UIAlert
                        message="Si è verificato un errore"
                        description={current}
                        type="error"
                        showIcon
                      />
                    </Col>
                  </Row>
                ))}
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={handleLogin}
              onFinishFailed={(e): void => console.log(e)}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Inserisci un'email valida!" }]}
              >
                <UIInput placeholder="Email" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Inserisci una password valida!" }]}
              >
                <UIInputPassword placeholder="Password" prefix={<UnlockOutlined />} />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Ricordami</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <UIButton
                  bgColor={Colors.title}
                  color={Colors.primary}
                  hoverBgColor={Colors.title}
                  borderColor={Colors.title}
                  htmlType="submit"
                  fullWidth
                >
                  ACCEDI
                </UIButton>
              </Form.Item>
              <Form.Item name="lostPassword" noStyle>
                <span
                  style={{ color: Colors.title }}
                  onClick={() => history.push(Routes.forgotPassword)}
                >
                  Password dimenticata?
                </span>
              </Form.Item>
            </Form>
          </UICard>
        </Col>
      </Row>
    </>
  );
};
