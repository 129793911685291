/** @format */

import React from "react";
import { Menu, Dropdown } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Colors } from "../../theme/maps/Colors";
import { UIText } from "../ui/Text";

const NoticeListColumnsRenderer = (value: string): JSX.Element => {
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<DeleteOutlined />}>
        Cancella
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <UIText css={"font-size: 24px; cursor: pointer;"} color={Colors.title}>
        ...
      </UIText>
    </Dropdown>
  );
};

export default NoticeListColumnsRenderer;
