/** @format */

import { TStore } from "../declarations/store";
import { TAlert, TRequestStatus } from "../declarations/maps/ui";
import { TAPIValidationError } from "../declarations/apis/general";

export const selectorLoginValidation = (store: TStore): TAPIValidationError =>
  store.ui.loginValidation || {};

export const selectorUsersListLoading = (store: TStore): boolean =>
  store.ui.usersListLoading || false;

export const selectorGroupsListLoading = (store: TStore): boolean =>
  store.ui.groupsListLoading || false;

export const selectorLocationsListLoading = (store: TStore): boolean =>
  store.ui.locationsListLoading || false;

export const selectorUserCreated = (store: TStore): boolean => store.ui.userCreated || false;
export const selectorUserUpdated = (store: TStore): boolean => store.ui.userUpdated || false;
export const selectorUserDeleted = (store: TStore): boolean => store.ui.userDeleted || false;

export const selectorUserAvatarUpdated = (store: TStore): boolean =>
  store.ui.userAvatarUpdated || false;

export const selectorUserDocumentUpdated = (store: TStore): boolean =>
  store.ui.userDocumentUpdated || false;

export const selectorGroupCreated = (store: TStore): boolean => store.ui.groupCreated || false;
export const selectorGroupUpdated = (store: TStore): boolean => store.ui.groupUpdated || false;
export const selectorGroupDeleted = (store: TStore): boolean => store.ui.groupDeleted || false;

export const selectorLocationCreated = (store: TStore): boolean =>
  store.ui.locationCreated || false;
export const selectorLocationUpdated = (store: TStore): boolean =>
  store.ui.locationUpdated || false;
export const selectorLocationDeleted = (store: TStore): boolean =>
  store.ui.locationDeleted || false;

export const selectorNoticeListStatus = (store: TStore): TRequestStatus =>
  store.ui.noticeListStatus || "idle";

export const selectorNoticeCreateStatus = (store: TStore): TRequestStatus =>
  store.ui.noticeCreateStatus || "idle";
export const selectorDocumentTypeCreated = (store: TStore): boolean =>
  store.ui.documentTypeCreated || false;
export const selectorDocumentTypeUpdated = (store: TStore): boolean =>
  store.ui.documentTypeUpdated || false;
export const selectorDocumentTypeDeleted = (store: TStore): boolean =>
  store.ui.documentTypeDeleted || false;

//#region alert

export const selectorAlert = (store: TStore): TAlert | undefined => store.ui.alert;
export const selectorStaticAlert = (store: TStore): TAlert | undefined => store.ui.staticAlert;

//#endregion
