import { TGroup, TGroupComplete } from "../../redux-modules/declarations/maps/groups";

export const utilityFormatApiGroup = (group: TGroup): TGroup => ({
  id: group.id,
    name: group.name,
    //@ts-ignore
    location: group.location || '',
    //@ts-ignore
    usersCount: group.usersCount || ''
  });
  

export const utilityFormatApiGroupList = (list: TGroup[]): TGroup[] =>
  list.map(group => utilityFormatApiGroup(group));

  export const utilityFormatFindList = (group: TGroupComplete): TGroup => ({
    id: group.id,
    name: group.name,
    manager: group.manager?.id
  })