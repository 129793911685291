/** @format */

import { TAPIMeta } from "../../redux-modules/declarations/apis/general";
import { TPagination } from "../../redux-modules/declarations/maps/ui";

export const utilityFormatApiMetaToPagination = ({
  current_page,
  total,
  per_page,
  to,
}: TAPIMeta): TPagination => ({
  current: current_page,
  pageSize: per_page,
  lastPage: to,
  total,
});
