/** @format */

import { TAPINotice, TAPINoticeList } from "../../redux-modules/declarations/apis/notices";
import { TNotice, TNoticeList } from "../../redux-modules/declarations/maps/notices";
import { utilityFormatApiAttachmentList } from "./apiAttachment";

export const utilityFormatApiNotice = (notice: TAPINotice): TNotice => ({
  ...notice,
  id: JSON.stringify(notice),
  attachments: utilityFormatApiAttachmentList(notice.attachments),
  recipient_count: JSON.stringify(notice.recipient_count),
});

export const utilityFormatApiNoticeList = (list: TAPINoticeList): TNoticeList =>
  list.map(current => utilityFormatApiNotice(current));
