/** @format */
import { ColumnsType } from "antd/lib/table";
import RequestListColumnsRenderer from "../../components/renderers/RequestTableMoreActions";
import TextRenderer from "../../components/renderers/Text";

export const requestListColumns: ColumnsType<object> = [
  {
    title: "Titolo",
    dataIndex: "title",
    key: "title",
    render: TextRenderer,
  },
  {
    title: "Stato",
    dataIndex: "status",
    key: "status",
    render: TextRenderer,
  },
  {
    align: "center",
    title: "Azioni",
    dataIndex: "actions",
    key: "actions",
    render: RequestListColumnsRenderer,
  },
];
