/** @format */

import { History } from "history";
import { combineReducers } from "redux";
import { reducerAuth } from "./reducerAuth";
import { reducerUI } from "./reducerUI";
import { reducerUsers } from "./reducerUsers";
import { reducerGroups } from "./reducerGroups";
import { reducerLocations } from "./reducerLocations";
import { reducerNotices } from "./reducerNotices";
import { reducerDocumentTypes } from "./reducerDocumentTypes";
import { connectRouter } from "connected-react-router";
import { reducerDocuments } from "./reducerDocuments";

export const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ui: reducerUI,
    auth: reducerAuth,
    users: reducerUsers,
    groups: reducerGroups,
    locations: reducerLocations,
    notices: reducerNotices,
    documentTypes: reducerDocumentTypes,
    documents: reducerDocuments,
  });
