/** @format */

import { TAPIEntry, TAPIResponse } from "../declarations/apis/general";
import { useEffect, useState } from "react";
import apiClient from "../../utils/apiClient";
import { AxiosResponse } from "axios";
import { TEntry, TList, TParamList } from "../declarations/maps/general";
import { TObject, utilityMapObjectArray } from "../../utils/mapObjects";

export interface TTreeEntry {
  title: string;
  value: string;
  key: string;
  children?: TTreeEntry[];
}

export interface TResponseEntry extends TEntry {
  groupId: string;
}

type TReturn = [TTreeEntry[], boolean, boolean, (newParams: TParamList) => void];

export const useTreeOptionList = (
  endpoint: string,
  map: TList<string>,
  groupOptionList: TEntry[],
  defaultParams?: TParamList,
): TReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [optionList, setOptionList] = useState<TTreeEntry[]>([]);
  const [params, setParams] = useState(defaultParams);

  useEffect(() => {
    if (!groupOptionList || !groupOptionList.length) return;
    setIsLoading(true);
    apiClient
      .get(endpoint, { params })
      .then((response: AxiosResponse<TAPIResponse<TObject[] | TAPIEntry[]>>) => {
        const mapResponse = utilityMapObjectArray<TResponseEntry[]>(
          response.data.data as TObject[],
          map,
        );
        const merged = utilityMergeObjects(mapResponse, groupOptionList);
        console.log("MAPPED RESPONSE:", mapResponse);
        console.log("GROUP OPTION LIST", groupOptionList);
        console.log("MERGED", merged);
        setOptionList(merged);
        setIsLoading(false);
        setHasFailed(false);
      })
      .catch(reason => {
        setIsLoading(false);
        setHasFailed(true);
      });
  }, [endpoint, params, groupOptionList]);

  return [optionList, isLoading, hasFailed, setParams];
};

export const utilityMergeObjects = (
  optionList: TResponseEntry[],
  groupList: TEntry[],
): TTreeEntry[] =>
  groupList
    .map<TTreeEntry>(group => ({
      title: group.value,
      key: group.id,
      value: group.id,
      children: utilityMergeObjectChildren(optionList, group),
    }))
    .filter(current => !!current.children?.length);

export const utilityMergeObjectChildren = (
  optionList: TResponseEntry[],
  groupList: TEntry,
): TTreeEntry[] =>
  optionList
    .filter(x => x.groupId === groupList.value)
    .map<TTreeEntry>(({ id, value }) => ({
      title: value,
      key: `${groupList.id}-${id}`,
      value: `${groupList.id}-${id}`,
    }));
