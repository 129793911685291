/** @format */

import React, { useRef, useState } from "react";
import { Col, Modal, Row } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

interface Props {
  pdfUrl: string;
  visible: boolean;
  onDismiss: () => void;
}

export const UIFilePreview = ({ pdfUrl, visible, onDismiss }: Props): JSX.Element => {
  //  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdfWorker.js`;
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const containerRef = useRef<HTMLDivElement>(null);

  //@ts-ignore
  const onDocumentLoadSuccess = (pdf): void => setNumPages(pdf.numPages);

  const pagePrev = (): void => setPageNumber(pageNumber - 1);
  const pageNext = (): void => setPageNumber(pageNumber + 1);
  const isFirstPage = pageNumber <= 1;
  //@ts-ignore
  const isLastPage = pageNumber >= numPages || false;

  return (
    <Modal title="Anteprima file" visible={visible} onOk={onDismiss} onCancel={onDismiss}>
      <div style={{ width: "100%" }} ref={containerRef}>
        <Document
          file={{ url: pdfUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          error="Impossibile caricare il file"
        >
          <Page pageNumber={pageNumber} width={containerRef.current?.offsetWidth} />
        </Document>
        <Row justify="center" style={{ display: !numPages ? "none" : "" }}>
          <Col>
            <div
              style={{ cursor: !isFirstPage ? "pointer" : "not-allowed", margin: "5px" }}
              onClick={!isFirstPage ? pagePrev : undefined}
            >
              <CaretLeftOutlined />
            </div>
          </Col>
          <Col>
            <div
              style={{ cursor: !isLastPage ? "pointer" : "not-allowed", margin: "5px" }}
              onClick={!isLastPage ? pageNext : undefined}
            >
              <CaretRightOutlined />
            </div>
          </Col>
          <Col style={{ position: "absolute", right: "25px" }}>
            <p>
              {pageNumber}/{numPages}
            </p>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
