/** @format */

import { Row, Col } from "antd";
import React from "react";
import { useLocation, useHistory } from "react-router";
import { SETTINGSTABS } from "../../../../../constants/settings";
import { requestTypeListColumns } from "../../../../../constants/tables/requestTypeListColumns";
import { Routes } from "../../../../../utils/Routes";
import { UIButton } from "../../../../ui/Button";
import { UITable } from "../../../../ui/Table";
import { UITitle } from "../../../../ui/Title";
import UITransition from "../../../../ui/Transition";
import ViewRequestTypeCreate from "../../../ViewRequestTypeCreate";
import { ViewDocumentTypeEdit } from "../DocumentTypes/ViewDocumentTypeEdit";

export const RequestTypesTab = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  /* const [requestTypes, setRequestTypes] = useState([]);
  const dispatch = useDispatch();
  const requestTypeDeleted = useSelector(selectorRequestTypeDeleted);
  const requestTypeList = useSelector(selectorRequestTypesList);
 */
  /*   useEffect(() => {
    if (history.location.pathname === Routes.settings + SETTINGSTABS.REQUEST_TYPES) {
       dispatch(actionRequestTypeListRequest());
     }
  }, [history.location.pathname, requestTypeDeleted]);
 */

  switch (location.pathname) {
    case Routes.settings + SETTINGSTABS.REQUEST_TYPES + "/nuovo":
      return <ViewRequestTypeCreate />;
    case Routes.settings + SETTINGSTABS.REQUEST_TYPES:
      return (
        <UITransition>
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col>
              <UITitle level={4}>Tipologia Richieste</UITitle>
            </Col>
            <Col>
              <span
                onClick={() =>
                  history.push(Routes.settings + SETTINGSTABS.REQUEST_TYPES + "/nuovo")
                }
              >
                <UIButton type="primary">Crea Nuova</UIButton>
              </span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <UITable
                //expandable={{ expandedRowRender: NoticeExpand }}
                //loading={isPending}
                columns={requestTypeListColumns}
                dataSource={[{ title: "Test", status: "Accettata", actions: "1" }]}
                pagination={false}
                size="middle"
                //onChange={handleTableChange}
                //pagination={{ ...pagination }}
              />
            </Col>
          </Row>
        </UITransition>
      );
    default:
      return <ViewDocumentTypeEdit />;
  }
};
