/** @format */

import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { UITitle } from "../../ui/Title";
import { Colors } from "../../../theme/maps/Colors";
import { UISearch } from "../../ui/SearchInput";
import { UITable } from "../../ui/Table";
import { UIButton } from "../../ui/Button";
import { Routes } from "../../../utils/Routes";
import { groupListColumns } from "../../../constants/tables/groupListColumns";
import { TablePaginationConfig } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedInput } from "../../../redux-modules/hooks/useDebouncedInput";
import { selectorGroupsList } from "../../../redux-modules/selectors/selectorsGroups";
import {
  selectorGroupDeleted,
  selectorGroupsListLoading,
} from "../../../redux-modules/selectors/selectorsUI";
import { utilityFormatGroupsToTableDataSourceList } from "../../../utils/format/groupTable";
import { actionGroupsListRequest } from "../../../redux-modules/actions/actionsGroups";
import { UICard } from "../../ui/Card";
import UITransition from "../../ui/Transition";
import { useHistory } from "react-router";
import { UILink } from "../../ui/Link";

export const ViewGroupList = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const groupList = useSelector(selectorGroupsList);
  const groupListLoading = useSelector(selectorGroupsListLoading);
  const isGroupDeleted = useSelector(selectorGroupDeleted);

  useEffect(() => {
    if (isGroupDeleted)
      dispatch(
        actionGroupsListRequest({
          query: debouncedQuery || undefined,
          columns: ["name"],
        }),
      );
  }, [isGroupDeleted]);

  const [debouncedQuery, currentQuery, setQuery] = useDebouncedInput("", () => true, false, 600);

  const formattedGroupList = utilityFormatGroupsToTableDataSourceList(groupList.data);

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    dispatch(
      actionGroupsListRequest({
        page: JSON.stringify(pagination.current || pagination.defaultCurrent),
        query: debouncedQuery || undefined,
        columns: ["name"],
      }),
    );
  };

  console.log(groupList.pagination);

  useEffect(() => {
    dispatch(
      actionGroupsListRequest({
        query: debouncedQuery || undefined,
        columns: ["name"],
      }),
    );
  }, [debouncedQuery]);
  console.log(groupList);
  return (
    <UITransition>
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <UITitle level={2} color={Colors.title}>
            Gruppi
          </UITitle>
        </Col>
        <Col>
          <UILink
            route={Routes.groupsNew}
            label={<UIButton type="primary">+ Crea Gruppo</UIButton>}
          />
        </Col>
      </Row>
      <UICard>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col />
          <Col>
            <UISearch placeholder="Cerca..." onChange={setQuery} value={currentQuery} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UITable
              size="middle"
              loading={groupListLoading}
              columns={groupListColumns}
              dataSource={formattedGroupList}
              onChange={handleTableChange}
              pagination={{ ...groupList.pagination }}
            />
          </Col>
        </Row>
      </UICard>
    </UITransition>
  );
};
