/** @format */

import { AxiosResponse } from "axios";
import apiClient from "../utils/apiClient";
import { API_ENDPOINTS } from "../constants/api";
import { TAPIUsersListRequest } from "../redux-modules/declarations/apis/users";
import { TUserAvatarRequest, TUserCreateRequest, TUserDocumentUploadRequest, TUserUpdateRequest } from "../redux-modules/declarations/maps/users";

export const apiUsersList = (params: TAPIUsersListRequest): Promise<AxiosResponse<unknown>> =>
  apiClient.get(API_ENDPOINTS.USERS_LIST, { params });

export const apiUserCreate = (request: TUserCreateRequest): Promise<AxiosResponse<unknown>> => 
apiClient.post(API_ENDPOINTS.USERS_LIST, request);

export const apiUserUpdate = (request: TUserUpdateRequest): Promise<AxiosResponse<unknown>> => 
apiClient.put(API_ENDPOINTS.USERS_LIST + "/" + request.id, request);

export const apiUserDelete = (userId: string,): Promise<AxiosResponse<unknown>> =>
apiClient.delete(API_ENDPOINTS.USERS_LIST + '/' + userId);

export const apiUserAvatar = (request: TUserAvatarRequest): Promise<AxiosResponse<unknown>> =>{
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  var formData = new FormData();
  formData.append("avatar", request.avatar);
  formData.append("id", request.id);

  return apiClient.post(API_ENDPOINTS.USER_AVATAR.replace(':id_user',request.id), formData, config);
}

export const apiUserDocumentShow = (id: string): Promise<AxiosResponse<unknown>> =>{
  return apiClient.get(API_ENDPOINTS.DOCUMENTS + "/" + id);
}

export const apiUserDocumentUpload = (request: TUserDocumentUploadRequest): Promise<AxiosResponse<unknown>> =>{
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  var formData = new FormData();
  formData.append("file", request.file);
  formData.append("user_id", request.user_id);
  formData.append("document_type_id", request.document_type_id);
  formData.append("valid_at", request.valid_at);

  return apiClient.post(API_ENDPOINTS.DOCUMENTS, formData, config);
}
