/** @format */

import { TAPIEntry, TAPIResponse } from "../declarations/apis/general";
import { useEffect, useState } from "react";
import apiClient from "../../utils/apiClient";
import { AxiosResponse } from "axios";
import { utilityFormatApiEntryList } from "../../utils/format/apiEntry";
import { TEntry, TList, TParamList } from "../declarations/maps/general";
import { TObject, utilityMapObjectArray } from "../../utils/mapObjects";

type TReturn = [TEntry[], boolean, boolean, (newParams: TParamList) => void];

export const useOptionList = (
  endpoint: string,
  defaultParams?: TParamList,
  map?: TList<string>,
): TReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [optionList, setOptionList] = useState<TEntry[]>([]);
  const [params, setParams] = useState(defaultParams);

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .get(endpoint, { params })
      .then((response: AxiosResponse<TAPIResponse<TObject[] | TAPIEntry[]>>) => {
        if (!!map) {
          const mapResponse = utilityMapObjectArray<TEntry[]>(response.data.data as TObject[], map);
          console.log("MAPPED OPTION LIST", mapResponse);
          setOptionList(mapResponse as TEntry[]);
        } else {
          setOptionList(utilityFormatApiEntryList(response.data.data as TAPIEntry[]));
        }
        setIsLoading(false);
        setHasFailed(false);
      })
      .catch(reason => {
        setIsLoading(false);
        setHasFailed(true);
      });
  }, [endpoint, params]);

  return [optionList, isLoading, hasFailed, setParams];
};
