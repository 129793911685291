/** @format */

import {
  TNoticeCreateRequest,
  TNoticeListRequest,
  TNoticeTable,
} from "../declarations/maps/notices";
import {
  TActionNoticeCreateFailure,
  TActionNoticeCreateRequest,
  TActionNoticeCreateReset,
  TActionNoticeCreateSuccess,
  TActionNoticeListFailure,
  TActionNoticeListRequest,
  TActionNoticeListReset,
  TActionNoticeListSuccess,
} from "../declarations/actions/notices";
import { TActionType } from "../declarations/actionTypes";

//region List

export const actionNoticeListRequest = (payload: TNoticeListRequest): TActionNoticeListRequest => ({
  type: TActionType.noticeListRequest,
  payload,
});

export const actionNoticeListSuccess = (payload: TNoticeTable): TActionNoticeListSuccess => ({
  type: TActionType.noticeListSuccess,
  payload,
});

export const actionNoticeListFailure = (reason: string): TActionNoticeListFailure => ({
  type: TActionType.noticeListFailure,
  reason,
});

export const actionNoticeListReset = (): TActionNoticeListReset => ({
  type: TActionType.noticeListReset,
});

//endregion

//region Create

export const actionNoticeCreateRequest = (
  payload: TNoticeCreateRequest,
): TActionNoticeCreateRequest => ({ type: TActionType.noticeCreateRequest, payload });

export const actionNoticeCreateSuccess = (): TActionNoticeCreateSuccess => ({
  type: TActionType.noticeCreateSuccess,
});

export const actionNoticeCreateFailure = (reason: string): TActionNoticeCreateFailure => ({
  type: TActionType.noticeCreateFailure,
  reason,
});

export const actionNoticeCreateReset = (): TActionNoticeCreateReset => ({
  type: TActionType.noticeCreateReset,
});

//endregion
