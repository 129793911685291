/** @format */

export enum Routes {
  home = "/",
  login = "/login",
  forgotPassword = "/recupero/password",
  users = "/utenti",
  usersNew = "/utenti/nuovo",
  usersEdit = "/utenti/:id_user",
  groups = "/gruppi",
  groupsNew = "/gruppi/nuovo",
  groupsEdit = "/gruppi/:id_group",
  locations = "/sedi",
  locationsNew = "/sedi/nuova",
  locationsEdit = "/sedi/:id_location",
  userProfile = "/profilo",
  documents = "/documenti",
  documentsNew = "/documenti/nuovo",
  documentsManagement = "/gestione/documenti",
  notices = "/avvisi",
  noticesNew = "/avvisi/nuovo",
  noticesEdit = "/avvisi/:id_notice",
  settings = "/configurazione",
  requests = "/richieste",
  requestNew = "/richiesta/nuova",
  requestTypeNew = "/tipo/richiesta/nuova",
}
