/** @format */

import { TRequestStatus } from "../declarations/maps/ui";
import { useEffect, useState } from "react";

interface TReturn {
  isPending: boolean;
  hasFailed: boolean;
  hasSucceded: boolean;
  isIdle: boolean;
}

/**
 * @description Describe the current request status using booleans
 * @param status The status that needs to be described
 * @returns An object containing a set of booleans describing the specified request status
 */
export const useRequestStatus = (status: TRequestStatus): TReturn => {
  const [isPending, setIsPending] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [hasSucceded, setHasSucceded] = useState(false);
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    setIsPending(status === "pending");
    setHasFailed(status === "failure");
    setHasSucceded(status === "success");
    setIsIdle(status === "idle");
  }, [status]);

  return { isPending, hasFailed, hasSucceded, isIdle };
};
